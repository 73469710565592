import React, { Fragment, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import Tools from '../../js/tools'
import { BtnVer, BtnEliminar } from '../common/table_buttons'
import { GetPermisos } from '../common/getPermisos';
import Table from '../common/tabla'
import notie from 'notie'
import Breadcrumb from '../common/breadcrumbv2';
import { Home } from 'react-feather';

const Default = ({
    maxMinSucAlm,
    setMaxMinSucAlm,
    ctgUnidades,
    setOnClickFormRefresh,
    esEditar
}) => {
    const [esRefresh, setEsRefresh] = useState(false)
    const navigate = useNavigate()
    const params = useParams()

    const eliminar = (data) => {
        Tools.toolFetch({
            ruta: 'rt_insumos_eliminar',
            method: 'POST',
            body: data,
        }, (response) => {
            if (response.estatus === 'ERROR') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persiste el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 2, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            setEsRefresh((s) => !s)
            notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Se ha eliminado este registro.`,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }

    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className='card-body'>
                                <div className='row'>
                                    <div className="col-xl-12 col-md-12">
                                        <Table
                                            noRutaDinamica={true}
                                            cargando={false}
                                            headers={[
                                                {
                                                    nombre: 'TITULO', tipo: 'html', texto: 'TITULO', html: (d) => {
                                                        return <div>
                                                            <strong>{d.TITULO}</strong><br />
                                                        </div>
                                                    }
                                                },
                                                {
                                                    nombre: 'SUCURSALES/ALMACENES', tipo: 'html', texto: 'TIPO_TITULO', html: (d) => {
                                                        return <div>
                                                            <strong>{d.TIPO_TITULO}</strong><br />
                                                        </div>
                                                    }
                                                },
                                                {
                                                    nombre: 'CANTIDAD MINIMA',
                                                    tipo: 'input',
                                                    texto: 'CANTIDAD_MINIMA',
                                                    valueOp: (fila, value) => {
                                                        setMaxMinSucAlm(l => {
                                                            l[fila.CLAVE_CHECK].cantidad_minima = value
                                                            return l
                                                        })

                                                        return { CANTIDAD_MINIMA: value }
                                                    }
                                                },
                                                {
                                                    nombre: 'UNIDAD MINIMA',
                                                    tipo: 'select',
                                                    value: 'UNIDAD_MINIMA',
                                                    options: ctgUnidades,
                                                    valueOp: (fila, value) => {
                                                        console.log(fila.CLAVE_CHECK);

                                                        setMaxMinSucAlm(l => {
                                                            l[fila.CLAVE_CHECK].unidad_minima = value
                                                            return l
                                                        })

                                                        return { UNIDAD_MINIMA: value }
                                                    }
                                                },
                                                {
                                                    nombre: 'CANTIDAD MAXIMA',
                                                    tipo: 'input',
                                                    texto: 'CANTIDAD_MAXIMA',
                                                    valueOp: (fila, value) => {
                                                        setMaxMinSucAlm(l => {
                                                            l[fila.CLAVE_CHECK].cantidad_maxima = value
                                                            return l
                                                        })

                                                        return { CANTIDAD_MAXIMA: value }
                                                    }
                                                },
                                                {
                                                    nombre: 'UNIDAD MAXIMA',
                                                    tipo: 'select',
                                                    value: 'UNIDAD_MAXIMA',
                                                    options: ctgUnidades,
                                                    valueOp: (fila, value) => {
                                                        setMaxMinSucAlm(l => {
                                                            l[fila.CLAVE_CHECK].unidad_maxima = value
                                                            return l
                                                        })

                                                        return { UNIDAD_MAXIMA: value }
                                                    }
                                                },
                                                // {
                                                //     nombre: 'TELEFONO', tipo: 'html', texto: 'TELEFONO_INA', html: (d) => {
                                                //         return <div>
                                                //             <strong>{d.TELEFONO_INA}</strong><br />
                                                //         </div>
                                                //     }
                                                // },
                                                // {
                                                //     nombre: 'CELULAR', tipo: 'html', texto: 'CELULAR_INA', html: (d) => {
                                                //         return <div>
                                                //             <strong>{d.CELULAR_INA}</strong><br />
                                                //         </div>
                                                //     }
                                                // },
                                            ]}
                                            totalField={'NR'} /* NR = número de resultados totales */
                                            path={'/rt_insumos_sucursales_almacenes_artins'}
                                            sinBuscador={true}
                                            esPaginador={false}
                                            refresh={esRefresh}
                                            body={{ ...params, maxMinSucAlm }}
                                        >
                                        </Table>
                                        <div className="col-md-12 mb-3">
                                            <div className="justify-content-end d-flex">
                                                {(esEditar && (GetPermisos.find(permiso => [2051].includes(permiso)) ? true : false)) ||
                                                    (!esEditar && (GetPermisos.find(permiso => [21].includes(permiso)) ? true : false)) ?
                                                    <button className='btn-nuevo' type='button' onClick={(e) => { setOnClickFormRefresh(l => !l) }}>
                                                        <span className="text">Guardar</span>
                                                        <div className="icon-container">
                                                            <div className="icon icon--left">
                                                            </div>
                                                            <div className="icon icon--right">
                                                            </div>
                                                        </div>
                                                    </button> : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Default;