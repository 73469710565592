import React, { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import Tools from '../../js/tools'
import { BtnVer, BtnEliminar } from '../common/table_buttons'
import Table from '../common/tabla'
import notie from 'notie'
import Breadcrumb from '../common/breadcrumbv2';
import { GetPermisos } from '../common/getPermisos';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Home } from 'react-feather';
import tools from '../../js/tools';

const Default = (props) => {
    const [esRefresh, setEsRefresh] = useState(false)
    const [modal5, setModal5] = useState();
    const [esEditar, setEsEditar] = useState(false)
    const [idEdit, setIdEdit] = useState(0)
    const [formUn, setFormUn] = useState({})
    const navigate = useNavigate()

    const toggle5 = () => {
        setModal5(!modal5)
    }

    const eliminar = (data) => {
        Tools.toolFetch({
            ruta: 'rt_unidad_medida_eliminar',
            method: 'POST',
            body: data,
        }, (response) => {
            console.log(response)
            if (response.estatus === 'ERROR') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persiste el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 2, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            setEsRefresh((s) => !s)
            notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Se ha eliminado este registro.`,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }

    const detalle = (id) => {
        Tools.toolFetch({
            ruta: 'rt_unidad_medida_detalle',
            method: 'POST',
            body: { id },
        }, (response) => {
            setEsEditar(true)
            setIdEdit(id)
            setFormUn(response.datos)
            toggle5()
        })
    }

    const onClickForm = () => {
        const form = tools.getForm('formCu')
        form.data.id = idEdit

        if (form.isEmtyCount > 0) {
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Ingrese los campos: ` + form.isEmtyString,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        Tools.toolFetch({
            ruta: !esEditar ? 'rt_unidad_medida_nuevo' : 'rt_unidad_medida_editar',
            method: 'POST',
            body: { datos: form.data },
        }, (response) => {
            if (response.status === 'ERROR') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persiste el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 2, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            setTimeout(() => {
                setEsRefresh((s) => !s)
                toggle5()
            }, 2000);

            notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Los datos se han guardado correctamente`,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }

    return (
        <Fragment>
            <Breadcrumb icon={Home} title={'Unidades de medida'} parent={[{ titulo: 'unidades de medida', link: '/unidad-medida' }]} />
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-xl-12 col-md-12">
                                        <Modal isOpen={modal5} toggle={toggle5}>
                                            <ModalHeader toggle={toggle5}>Unidada de medida</ModalHeader>
                                            <ModalBody>
                                                <form id='formCu'>
                                                    <div className="form-group">
                                                        <label className="col-form-label" htmlFor="recipient-name">Clave:</label>
                                                        <input
                                                            id='claveCu'
                                                            name='claveCu'
                                                            className="form-control"
                                                            type="text"
                                                            placeholder="Clave"
                                                            data-label="Clave"
                                                            max={10}
                                                            value={formUn.CLAVE_CU ? formUn.CLAVE_CU : ''}
                                                            onChange={(e) => { setFormUn(l => { return { ...l, CLAVE_CU: e.target.value } }) }} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="col-form-label" htmlFor="message-text">Nombre:</label>
                                                        <input id='nombreCu'
                                                            name='nombreCu'
                                                            className="form-control"
                                                            type="text"
                                                            placeholder="Nombre"
                                                            data-label="Nombre"
                                                            maxLength={50}
                                                            value={formUn.NOMBRE_CU ? formUn.NOMBRE_CU : ''}
                                                            onChange={(e) => { setFormUn(l => { return { ...l, NOMBRE_CU: e.target.value } }) }} />
                                                    </div>
                                                </form>
                                            </ModalBody>
                                            <ModalFooter>
                                                <Button color="primary" onClick={onClickForm}>Guardar</Button>
                                                <Button color="secondary" onClick={toggle5}>Cancelar</Button>
                                            </ModalFooter>
                                        </Modal>
                                        <Table
                                            noRutaDinamica={true}
                                            cargando={false}
                                            htmlBtnPag={
                                                <div className="btn-container">
                                                    {(GetPermisos.find(permiso => [2048].includes(permiso)) ? true : false) ?
                                                        <button className='btn-nuevo' onClick={() => {
                                                            setFormUn({})
                                                            setEsEditar(false)
                                                            toggle5()
                                                        }}>
                                                            <span className="text">Nuevo</span>
                                                            <div className="icon-container">
                                                                <div className="icon icon--left">
                                                                </div>
                                                                <div className="icon icon--right">
                                                                </div>
                                                            </div>
                                                        </button>
                                                        : ''
                                                    }
                                                </div>}
                                            headers={[
                                                {
                                                    nombre: 'CLAVE INTERNA', tipo: 'html', texto: 'CLAVE_CU', html: (d) => {
                                                        return <div>
                                                            <strong>{d.CLAVE_CU}</strong><br />
                                                        </div>
                                                    }
                                                },
                                                {
                                                    nombre: 'NOMBRE', tipo: 'html', texto: 'NOMBRE_CU', html: (d) => {
                                                        return <div>
                                                            <strong>{d.NOMBRE_CU}</strong><br />
                                                        </div>
                                                    }
                                                },
                                                {
                                                    nombre: 'Detalles', tipo: 'html', texto: 'Detalle',
                                                    html: (d) => {
                                                        return <div>
                                                            {(GetPermisos.find(permiso => [2049].includes(permiso)) ? true : false) ?
                                                                <BtnVer
                                                                    onClick={() => { detalle(d.ID_CU) }}
                                                                />
                                                                : ''
                                                            }
                                                            {(GetPermisos.find(permiso => [2050].includes(permiso)) ? true : false) ?
                                                                <BtnEliminar
                                                                    onClick={() => {
                                                                        notie.confirm({
                                                                            text: `Se eliminara <strong>${d.NOMBRE_CU}</strong> ¿estas de acuerdo?`,
                                                                            submitText: 'Si, eliminar',
                                                                            cancelText: 'No, Cancelar',
                                                                            cancelCallback: function (value) {
                                                                            },
                                                                            submitCallback: function (value) {
                                                                                eliminar({ idCu: d.ID_CU })
                                                                            },
                                                                        })
                                                                    }}
                                                                />
                                                                : ''
                                                            }
                                                        </div>
                                                    }
                                                }
                                            ]}
                                            totalField={'NR'} /* NR = número de resultados totales */
                                            path={'rt_unidad_medida_list'}
                                            refresh={esRefresh}
                                            body={{}}
                                            paginadorTop={true}
                                        >
                                        </Table>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    );
};

export default Default;