import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import Tools from '../../js/tools'
import { BtnVer, BtnEliminar } from '../common/table_buttons'
import { GetPermisos } from '../common/getPermisos';
import Table from '../common/tabla'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import Select from '../common/select-basic'
import notie from 'notie'
import Breadcrumb from '../common/breadcrumbv2';
import { Briefcase } from 'react-feather';
import tools from '../../js/tools';

const Default = ({
    idSuc,
    idPsu,
    authSuc,
    esEditar,
    confirmSuc,
    listPerSuc,
    ctgFamilias,
    fechaLimite,
    idProduccion,
    tituloSucursal,
    setCtgFamilias,
    setEsActualizar,
    artInsUcAlmacenes,
    listUnionArtInsRe,
    permisoComisariato,
    setListUnionArtInsRe
}) => {
    const [esRefresh, setEsRefresh] = useState(false)
    const [esRefreshCopia, setEsRefreshCopia] = useState(false)
    const [esRefreshActualizar, setEsRefreshActualizar] = useState(false)
    const [listUnionArtInsReCopia, setListUnionArtInsReCopia] = useState({})
    const [btnDisabled, setBtnDisabled] = useState(false)
    const [recetas, setRecetas] = useState([])
    const [tipoVal, setTipoVal] = useState(1)
    const [valFamilia, setValFamilia] = useState(0)
    const [valNombreFamilia, setValNombreFamilia] = useState('')
    const [tipoValNombre, setTipoValNombre] = useState('')
    const [ingredientes, setIngredientes] = useState([])
    const [modal5, setModal5] = useState();
    const firstCurrent = useRef(true)
    const navigate = useNavigate()
    const params = useParams()

    notie.setOptions({
        dateMonths: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
    })

    const opTipos = [{ clave: 1, label: 'Insumos' }, { clave: 2, label: 'Articulos' }, { clave: 3, label: 'Recetas' }]

    useEffect(() => {
        Tools.toolFetch({
            ruta: 'rt_produccion_obtener_familias',
            method: 'POST',
            body: { idPr: idProduccion, idSuc }
        }, (response) => {
            setCtgFamilias(response.datos)

            if (firstCurrent.current) {
                firstCurrent.current = false
                return
            }
            setEsRefresh(l => !l)
        })
    }, [idProduccion, esRefreshActualizar])


    useEffect(() => {
        setEsRefresh(l => !l)
    }, [authSuc, confirmSuc, idPsu])

    const toggle5 = () => {
        console.log(listUnionArtInsRe);

        let clon = {}
        opTipos.forEach(v => {
            clon[v.label] = Object.assign({}, listUnionArtInsRe[v.label])
        })

        setListUnionArtInsReCopia(clon)
        setTipoValNombre(opTipos[0].label)
        setTipoVal(1)
        setModal5(!modal5)
        setEsRefreshCopia(l => !l)
    }

    const actualizarProd = (data) => {
        data.fila[data.campo] = data.valor

        Tools.toolFetch({
            ruta: 'rt_produccion_sucursal_actualizar',
            method: 'POST',
            body: data.fila
        }, (response) => {
            if (response.status === 'ERROR') {
                return notie.alert({
                    type: 'warning',
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persiste el detalle comunica al equipo de Prointernet.`,
                    time: 2,
                    position: 'top'
                })
            }
        })
    }

    const autorizarSucursal = () => {
        return notie.confirm({
            text: `¿Desea autorizar la requisición de la sucursal "${tituloSucursal}"?`,
            submitText: 'Si, Autorizar',
            cancelText: 'No, Cancelar',
            cancelCallback: function (value) {
            },
            submitCallback: function (value) {
                Tools.toolFetch({
                    ruta: 'rt_produccion_autorizar_sucursal',
                    method: 'POST',
                    body: { idPr: idProduccion, idPsu }
                }, (response) => {
                    if (response.status === 'ERROR') {
                        return notie.alert({
                            type: 'warning',
                            text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persiste el detalle comunica al equipo de Prointernet.`,
                            time: 2,
                            position: 'top'
                        })
                    }

                    setEsActualizar(l => !l)
                    // setEsRefreshActualizar(l => !l)

                    return notie.alert({
                        type: 'success',
                        text: `La producción ha sido actualizada.`,
                        time: 2,
                        position: 'top'
                    })
                })
            },
        })
    }

    const enviarRequisicionSucursal = () => {
        notie.date({
            value: new Date(),
            submitText: 'Enviar',
            cancelText: 'Cancelar',
            submitCallback: (date) => {
                let diaActual = new Date()
                let diaSeleccionado = `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`
                diaActual = `${diaActual.getMonth() + 1}-${diaActual.getDate()}-${diaActual.getFullYear()}`

                if ((new Date(diaSeleccionado)).getTime() < (new Date(diaActual)).getTime()) {
                    setTimeout(() => {
                        enviarRequisicionSucursal()
                    }, 2000);

                    return notie.alert({
                        type: 'warning',
                        text: `Ingrese una fecha mayor`,
                        time: 2,
                        position: 'top'
                    })
                }

                setBtnDisabled(true)
                Tools.toolFetch({
                    ruta: 'rt_produccion_enviar_requisicion',
                    method: 'POST',
                    body: { idPsu, fecha: date }
                }, (response) => {
                    setBtnDisabled(false)
                    if (response.status === 'ERROR') return notie.alert({
                        type: 'warning',
                        text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persiste el detalle comunica al equipo de Prointernet.`,
                        time: 2,
                        position: 'top'
                    })

                    if (response.result[0][0].ESTATUS === 2) return notie.alert({
                        type: 'warning',
                        text: `Ya existe una requisición asignada para esta fecha de la sucursal ${tituloSucursal}.`,
                        time: 4,
                        position: 'top'
                    })

                    setEsActualizar(l => !l)
                    return notie.alert({
                        type: 'success',
                        text: `Su requisición ha sido enviada exitosamente.`,
                        time: 2,
                        position: 'top'
                    })
                })
            }
        })
    }

    const confirmarSucursal = () => {
        return notie.confirm({
            text: `¿Desea confirmar la requisición de la sucursal "${tituloSucursal}"?`,
            submitText: 'Si, confirmar',
            cancelText: 'No, Cancelar',
            cancelCallback: function (value) {
            },
            submitCallback: function (value) {
                Tools.toolFetch({
                    ruta: 'rt_produccion_confirmar_sucursal',
                    method: 'POST',
                    body: { idPr: idProduccion, idPsu }
                }, (response) => {
                    if (response.status === 'ERROR') {
                        return notie.alert({
                            type: 'warning',
                            text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persiste el detalle comunica al equipo de Prointernet.`,
                            time: 2,
                            position: 'top'
                        })
                    }

                    setEsActualizar(l => !l)
                    // setEsRefreshActualizar(l => !l)

                    return notie.alert({
                        type: 'success',
                        text: `La producción ha sido confirmada.`,
                        time: 2,
                        position: 'top'
                    })
                })
            },
        })
    }

    const estatusCintillo = () => {
        if (!authSuc && !fechaLimite) return <Alert color="primary" className="dark">
            Tiempo limite
        </Alert>

        if (authSuc) return <Alert color="success" className="dark">
            Autorizado
        </Alert>

        if (confirmSuc) return <Alert color="info" className="dark">
            Confirmado
        </Alert>

        // return <Alert color="warning" className="dark">
        //     Pendiente
        // </Alert>
    }

    const editarFamilia = () => {
        let lista = []
        Object.keys(listUnionArtInsReCopia).forEach((key) => {
            Object.keys(listUnionArtInsReCopia[key]).forEach((keys) => {
                lista.push(listUnionArtInsReCopia[key][keys])
            })
        })

        lista = lista.map(v => ({ id: v.ID, tipo: v.TIPO, activo: v.ACTIVO_PRODUCCION }))

        Tools.toolFetch({
            ruta: 'rt_produccion_editar_familia',
            method: 'POST',
            body: { idProduccion, lista }
        }, (response) => {
            if (response.status === 'ERROR') {
                return notie.alert({
                    type: 'warning',
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persiste el detalle comunica al equipo de Prointernet.`,
                    time: 2,
                    position: 'top'
                })
            }
            setListUnionArtInsRe(listUnionArtInsReCopia)
            setModal5(!modal5)
            setEsRefresh(l => !l)
        })
    }

    return (
        <Fragment>
            <Modal isOpen={modal5} toggle={toggle5}>
                <ModalHeader toggle={toggle5}>{valNombreFamilia}</ModalHeader>
                <ModalBody>
                    <div className="mt-3 px-4">
                        <div className='input-field select'>
                            <Select
                                data-label="Auditor"
                                defaultOptOff={true}
                                datos={opTipos}
                                value={tipoVal}
                                onChange={(e) => {
                                    setTipoValNombre(e.item.label)
                                    setTipoVal(parseInt(e.item.clave))
                                    setEsRefreshCopia(l => !l)
                                    // setListUnionArtInsReCopia(listUnionArtInsRe[e.item.label])
                                }}
                                // required
                                spellcheck="false"
                            />
                            <label htmlFor="validationTooltip02"><i class="fa fa-comment" aria-hidden="true"></i> Seleccionar Articulos/Insumos <span className="text-danger">*</span></label>
                        </div>
                    </div>
                    <Table
                        placeholderSearch={`Buscar ${tipoValNombre}...`}
                        tablaMensaje={`No se encuentran "${tipoValNombre}" en esta familia`}
                        noRutaDinamica={true}
                        cargando={false}
                        headers={[
                            {
                                nombre: 'PORTADA', tipo: 'html', texto: 'PORTADA', html: (d) => {
                                    return <div style={{ textAlign: 'center', width: '8em' }}>
                                        <img src={`/images/${d.TIPO === 1 ? 'articulos-insumos' : 'recetas'}/` + d.ID + '/' + d.RUTA_IMAGEN} width={100} height={100} />
                                    </div>
                                }
                            },
                            {
                                nombre: 'NOMBRE', tipo: 'html', texto: 'NOMBRE_INA', html: (d) => {
                                    return <div>
                                        <strong>{d.NOMBRE}</strong><br />
                                    </div>
                                }
                            },
                            // {
                            //     nombre: 'MEDIDA MAXIMA', tipo: 'html', texto: 'NOMBRE_MAX_CU', html: (d) => {
                            //         return <div style={{ width: '8em' }}>
                            //             <strong>{d.NOMBRE_MAX_CU}</strong><br />
                            //         </div>
                            //     }
                            // },
                            // {
                            //     nombre: 'MEDIDA MINIMA', tipo: 'html', texto: 'NOMBRE_MIN_CU', html: (d) => {
                            //         return <div style={{ width: '8em' }}>
                            //             <strong>{d.NOMBRE_MIN_CU}</strong><br />
                            //         </div>
                            //     }
                            // },
                            // {
                            //     nombre: 'PROVEEDOR', tipo: 'html', texto: 'TITULO_PRV', html: (d) => {
                            //         return <div>
                            //             <strong>{d.TITULO_PRV}</strong><br />
                            //         </div>
                            //     }
                            // },
                            {
                                nombre: 'SELECCIONAR', tipo: 'check', texto: 'ID_INA', callback: (d) => {
                                    setListUnionArtInsReCopia(l => {
                                        l[tipoValNombre][d.ID] = { ...l[tipoValNombre][d.ID], ACTIVO_PRODUCCION: !l[tipoValNombre][d.ID].ACTIVO_PRODUCCION }

                                        console.log(l[tipoValNombre]);
                                        return l
                                    })
                                }
                            },
                        ]}
                        totalField={'NR'} /* NR = número de resultados totales */
                        path={'/rt_produccion_artins_list'}
                        refresh={esRefreshCopia}
                        body={{ tipo: 1, valNombre: tipoValNombre, valFamilia, list: listUnionArtInsReCopia }}
                        paginadorTop={true}
                    >
                    </Table>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={editarFamilia}>Guardar</Button>
                    <Button color="secondary" onClick={toggle5}>Cancelar</Button>
                </ModalFooter>
            </Modal>
            {estatusCintillo()}
            {ctgFamilias.map((v, index) =>
                <Fragment>
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div class="col-md-12 mb-3">
                                    <h5 className='title-card-general'>{v.label}</h5>
                                </div>
                                <div className="col-xl-12 col-md-12">
                                    <div className="mt-3 px-4">
                                        <Table
                                            noRutaDinamica={true}
                                            cargando={false}
                                            // formatTablaRefresh={recetas[index].formatTablaRefresh}
                                            // formatTabla={(datos) => ({ ...datos, CANTIDAD: datos.CANTIDAD_IR * recetas[index].valTiempo })}
                                            htmlTopleft={!esEditar && !confirmSuc && GetPermisos.find(permiso => [2095].includes(permiso)) ?
                                                <div className="col-md-6">
                                                    {/* <div className="d-flex justify-content-between align-items-end">
                                                        <button className='btn-nuevo' onClick={() => {
                                                            setValFamilia(v.clave)
                                                            setValNombreFamilia(v.label)
                                                            toggle5()
                                                        }}
                                                        >
                                                            <span className="text">Editar</span>
                                                            <div className="icon-container">
                                                                <div className="icon icon--left">
                                                                </div>
                                                                <div className="icon icon--right">
                                                                </div>
                                                            </div>
                                                        </button>
                                                    </div> */}
                                                </div>
                                                : ''
                                            }
                                            headers={[
                                                {
                                                    nombre: 'PORTADA', tipo: 'html', texto: 'PORTADA', html: (d) => {
                                                        return <div style={{ textAlign: 'center' }}>
                                                            <img src={'/images/' + (d.TIPO_PS === 1 ? 'articulos-insumos/' : 'recetas/') + d.ID + '/' + d.RUTA_IMAGEN} width={100} height={100} />
                                                        </div>
                                                    }
                                                },
                                                {
                                                    nombre: 'NOMBRE', tipo: 'html', texto: 'NOMBRE', html: (d) => {
                                                        return <div>
                                                            <strong>{d.NOMBRE} {d.NOMBRE_CPE ? `(${d.NOMBRE_CPE})` : ''}</strong><br />
                                                        </div>
                                                    }
                                                },
                                                {
                                                    nombre: 'TIPO', tipo: 'html', texto: 'TIPO_NOMBRE', html: (d) => {
                                                        return <div>
                                                            <strong>{d.TIPO_NOMBRE}</strong><br />
                                                        </div>
                                                    }
                                                },
                                                {
                                                    nombre: 'INVENTARIO',
                                                    tipo: 'input',
                                                    disabled: (!(GetPermisos.find(permiso => [2095].includes(permiso)) && listPerSuc.includes(idSuc))) || confirmSuc || !fechaLimite,
                                                    texto: 'INVENTARIO_PS',
                                                    valueOp: (fila, v) => {
                                                        let valor = v

                                                        if (valor < 0) valor = 0
                                                        if (valor > 9999999) return

                                                        const data = {
                                                            fila,
                                                            campo: 'INVENTARIO_PS',
                                                            valor: v
                                                        }

                                                        actualizarProd(data)
                                                        return { INVENTARIO_PS: v }
                                                    }
                                                    // disabled: true
                                                },
                                                {
                                                    nombre: 'PEDIDO',
                                                    tipo: 'input',
                                                    disabled: (!(GetPermisos.find(permiso => [2095].includes(permiso)) && listPerSuc.includes(idSuc))) || confirmSuc || !fechaLimite,
                                                    texto: 'PEDIDO_PS',
                                                    valueOp: (fila, v) => {
                                                        let valor = v

                                                        if (valor < 0) valor = 0
                                                        if (valor > 9999999) return

                                                        const data = {
                                                            fila,
                                                            campo: 'PEDIDO_PS',
                                                            valor: valor
                                                        }

                                                        actualizarProd(data)
                                                        return { PEDIDO_PS: valor }
                                                    }
                                                    // disabled: true
                                                },
                                                confirmSuc ? {
                                                    nombre: 'SURTIDO',
                                                    tipo: 'input',
                                                    texto: 'SURTIDO_PS',
                                                    toolTip: (fila) => authSuc || !fechaLimite || !((artInsUcAlmacenes.includes(fila.ID) && fila.TIPO_PS === 1) || (permisoComisariato && fila.TIPO_PS === 2)) ? 'No se poseen permisos de almacen' : null,
                                                    disabledValues: (fila) => authSuc || !fechaLimite || !((artInsUcAlmacenes.includes(fila.ID) && fila.TIPO_PS === 1) || (permisoComisariato && fila.TIPO_PS === 2))
                                                    ,
                                                    valueOp: (fila, v) => {
                                                        let valor = v

                                                        if (valor < 0) valor = 0
                                                        if (valor > 9999999) return

                                                        const data = {
                                                            fila,
                                                            campo: 'SURTIDO_PS',
                                                            valor: valor
                                                        }

                                                        actualizarProd(data)
                                                        return { SURTIDO_PS: valor }
                                                    }
                                                } : null,
                                                {
                                                    nombre: 'UNIDAD MINIMA', tipo: 'html', texto: 'UNIDAD_MINIMA', html: (d) => {
                                                        return <div>
                                                            <strong>{d.UNIDAD_MINIMA}</strong><br />
                                                        </div>
                                                    }
                                                },
                                                // {
                                                //     nombre: 'UNIDAD MAXIMA', tipo: 'html', texto: 'UNIDAD_MAXIMA', html: (d) => {
                                                //         return <div>
                                                //             <strong>{d.UNIDAD_MAXIMA}</strong><br />
                                                //         </div>
                                                //     }
                                                // },
                                                // {
                                                //     nombre: 'MERMA',
                                                //     tipo: 'input',
                                                //     texto: 'MERMA_PRR',
                                                //     disabled: !GetPermisos.find(permiso => [2095].includes(permiso)),
                                                //     valueOp: (d, v) => {
                                                //         let valor = v

                                                //         if (valor < 1) valor = 1
                                                //         if (valor > 9999) valor = 9999

                                                //         let _ingredientes = ingredientes[index].ingredientes.map(element => {
                                                //             return d.ID_INA === element.ID_INA ? { ...element, MERMA_PRR: valor } : element
                                                //         })

                                                //         setIngredientes(l => {
                                                //             l[index].ingredientes = _ingredientes

                                                //             actualizarProd(l)
                                                //             return l
                                                //         })

                                                //         return { MERMA_PRR: valor }
                                                //     }
                                                // },
                                                // {
                                                //     nombre: 'MINIMA', tipo: 'html', texto: 'UNIDAD_MINIMA', html: (d) => {
                                                //         return <div>
                                                //             <strong>{d.UNIDAD_MINIMA}</strong><br />
                                                //         </div>
                                                //     }
                                                // },
                                                // {
                                                //     nombre: 'MAXIMA', tipo: 'html', texto: 'UNIDAD_MAXIMA', html: (d) => {
                                                //         return <div>
                                                //             <strong>{d.UNIDAD_MAXIMA}</strong><br />
                                                //         </div>
                                                //     }
                                                // }
                                            ].filter(v => v)}
                                            totalField={'NR'} /* NR = número de resultados totales */
                                            path={'/rt_produccion_sucursal_list'}
                                            refresh={esRefresh}
                                            // results={(response) => {
                                            //     setIngredientes((l) => {
                                            //         l[index] = { id: v.ID_RE, ingredientes: response }
                                            //         return l
                                            //     })
                                            // }}
                                            esPaginador={false}
                                            // paginadorTop={true}
                                            sinBuscador={true}
                                            body={{ esEditar, idPr: idProduccion, idSuc: idSuc, idFi: v.clave }}
                                        >
                                        </Table>
                                    </div>
                                </div>
                                {(index === ctgFamilias.length - 1) && fechaLimite ?
                                    (
                                        GetPermisos.find(permiso => [2095].includes(permiso)) && !confirmSuc ?
                                            <div className="col-md-12 mb-3">
                                                <div className="justify-content-end d-flex">
                                                    <button
                                                        className='btn-nuevo'
                                                        type='button'
                                                        disabled={btnDisabled}
                                                        onClick={enviarRequisicionSucursal}>
                                                        <span className="text">Confirmar</span>
                                                        <div className="icon-container">
                                                            <div className="icon icon--left">
                                                            </div>
                                                            <div className="icon icon--right">
                                                            </div>
                                                        </div>
                                                    </button>
                                                </div>
                                            </div> : (
                                                GetPermisos.find(permiso => [4104].includes(permiso)) && confirmSuc && !authSuc ?
                                                    <div className="col-md-12 mb-3">
                                                        <div className="justify-content-end d-flex">
                                                            <button
                                                                className='btn-nuevo'
                                                                type='button'
                                                                disabled={btnDisabled}
                                                                onClick={autorizarSucursal}
                                                            >
                                                                <span className="text">Autorizar</span>
                                                                <div className="icon-container">
                                                                    <div className="icon icon--left">
                                                                    </div>
                                                                    <div className="icon icon--right">
                                                                    </div>
                                                                </div>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    : ''
                                            )
                                    )
                                    : ''
                                }
                            </div>
                        </div>
                    </div>
                </Fragment>)
            }
        </Fragment >
    );
};

export default Default;