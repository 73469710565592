import React, { Fragment, useState, useEffect, useRef } from 'react';
import Select from '../common/select-basic'
import Tools from '../../js/tools'
import notie from 'notie'
import InputLabel from '../common/inputLabel'
import { Home } from 'react-feather';
import Breadcrumb from '../common/breadcrumbv2';
import { useNavigate, useParams } from 'react-router-dom'
import { GetPermisos } from '../common/getPermisos';
import Dropzone from '../common/dropzone';
import SelectFecth from '../common/select-fetch';
import SelectSimple from '../common/select-simple';
import { NumericFormat } from 'react-number-format'
import TablaPrecios from '../common/tabla_estatica';

const Default = ({
    esEditar,
    formReq,
    setFormReq,
    listArtIns,
    formPag,
    setFormPag,
    setValueTab,
    setDataForm,
    esPlantilla,
    rutaPlantillas,
    ctgUsuarios,
    ctgAlmacenes,
    ctgUcAlmacenes,
    ctgEstatus,
    setParent
}) => {
    const [tipoVal, setTipoVal] = useState(0)
    const [listSelect, setListSelect] = useState({})
    const [almacenesSur, setAlmacenesSur] = useState([])
    const navigate = useNavigate()
    const firstCurrent = useRef(true)
    const params = useParams();


    useEffect(() => {
        if (firstCurrent.current) {
            firstCurrent.current = false
            return
        }

        const form = sigPag()
        if (form.activo) {
            setDataForm({ ...form.form, check_autorizar: form.form.check_autorizar ? true : false })
            setValueTab('1')
        }
    }, [formPag])

    useEffect(() => {
        setAlmacenesSur(ctgAlmacenes.filter(v => v.ID_ALM != formReq.ID_ALM_RQI))
    }, [formReq.ID_ALM_RQI, ctgAlmacenes])

    const sigPag = () => {
        const form = Tools.getForm('formReq', { ignore: ['txt_descripcion', 'clave_solicitante'] })

        if (form.isEmtyCount > 0) {
            notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Ingrese los campos: ` + form.isEmtyString,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })

            return { activo: 0 }
        }

        // if (articulosInsumos.length === 0) {
        //     notie.alert({
        //         type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        //         text: 'Ingrese algún Articulo/Insumo',
        //         // stay: Boolean, // optional, default = false
        //         time: 2, // optional, default = 3, minimum = 1,
        //         position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
        //     })

        //     return { activo: 0 }
        // }

        form.data.id = params.id
        form.data.esEditar = esEditar

        return { activo: 1, form: form.data }
        // Tools.toolFetch({
        //     ruta: !esEditar ? 'rt_requisiciones_nuevo' : 'rt_requisiciones_editar',
        //     method: 'POST',
        //     body: { datos: form.data, seleccionados: articulosInsumos },
        // }, (response) => {
        //     if (response.status === 'ERROR') {
        //         return notie.alert({
        //             type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        //             text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
        //             // stay: Boolean, // optional, default = false
        //             time: 2, // optional, default = 3, minimum = 1,
        //             position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
        //         })
        //     }

        //     if (!esEditar) {
        //         console.log(response[0].id);
        //         setTimeout(() => {
        //             navigate('/requisiciones-surtido/' + response[0].id)
        //         }, 2000);
        //     }

        //     notie.alert({
        //         type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        //         text: `Los datos se han guardado correctamente`,
        //         // stay: Boolean, // optional, default = false
        //         time: 2, // optional, default = 3, minimum = 1,
        //         position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
        //     })
        // })
    }

    return (
        <Fragment>
            {/* <Breadcrumb icon={Briefcase} title={'Familias insumos/articulos'} parent={['familias insumos']} /> */}
            <div className="container-fluid">
                <div className='row'>
                    <form id='formReq' className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className='card'>
                            {/* <div className="card-header d-flex justify-content-between">

                                <button className='btn-regresar' type='button' onClick={(e) => {
                                    navigate('/familias-insumos')
                                }}></button>
                                <h4><strong>{esEditar ? 'EDITAR' : 'NUEVA'} FAMILA DE INSUMOS/ARTICULOS</strong></h4>
                                <h4></h4>
                            </div> */}
                            <div className="card-body">
                                <div className='row'>
                                    <div className="col-md-12">
                                        <div className="row mb-5">
                                            <div class="col-md-12 mb-3">
                                                <h5 className='title-card-general'>Datos generales de la {esPlantilla || rutaPlantillas ? 'plantilla' : 'requisición'}</h5>
                                            </div>
                                            {/* <div className="col-md-6 mb-3">
                                                <div className='input-field'>
                                                    <input autoComplete='off'
                                                        data-label="clave interna"
                                                        id="txt_clave" name="txt_clave"
                                                        type="text"
                                                        value={formReq ? formReq.CLAVE_INTERNA_RQI : ''}
                                                        onChange={(e) => { setFormReq(l => { return { ...l, CLAVE_INTERNA_RQI: e.target.value } }) }}
                                                        required
                                                        spellcheck="false"
                                                    />
                                                    <label htmlFor="validationTooltip02"><span class="icon-icono_Materia-Prima"></span> Clave interna<span className="text-danger">*</span></label>
                                                </div>
                                                <span className="text-danger txtVacios txt_clave" style={{ display: 'none' }}><strong>Falta completar (Clave interna)</strong></span>
                                            </div> */}
                                            <div className="col-md-6 mb-3">
                                                <div className='input-field'>
                                                    <input autoComplete='off'
                                                        data-label="nombre"
                                                        id="txt_nombre" name="txt_nombre"
                                                        type="text"
                                                        maxLength={40}
                                                        value={formReq.NOMBRE_RQI}
                                                        onChange={!esEditar ? (e) => { setFormReq(l => { return { ...l, NOMBRE_RQI: e.target.value } }) } : null}
                                                        required
                                                        spellcheck="false"
                                                    />
                                                    <label htmlFor="validationTooltip02"><i class="fa fa-book" aria-hidden="true"></i> Nombre<span className="text-danger">*</span></label>
                                                </div>
                                                <span className="text-danger txtVacios txt_nombre" style={{ display: 'none' }}><strong>Falta completar (Nombre)</strong></span>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <div className='input-field'>
                                                    <input autoComplete='off'
                                                        data-label="titulo"
                                                        id="txt_titulo" name="txt_titulo"
                                                        type="text"
                                                        value={formReq.TITULO_RQI}
                                                        onChange={!esEditar ? (e) => { setFormReq(l => { return { ...l, TITULO_RQI: e.target.value } }) } : null}
                                                        required
                                                        spellcheck="false"
                                                    />
                                                    <label htmlFor="validationTooltip02"><i class="fa fa-tags" aria-hidden="true"></i> Titulo<span className="text-danger">*</span></label>
                                                </div>
                                                <span className="text-danger txtVacios txt_titulo" style={{ display: 'none' }}><strong>Falta completar (Titulo)</strong></span>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <div className='input-field select'>
                                                    <Select
                                                        data-label="almacen solicitante"
                                                        id="clave_almacen"
                                                        name="clave_almacen"
                                                        defaultOptOff={/*ctgUcAlmacenes.length > 1 &&*/ !(esEditar && !rutaPlantillas) ? false : true}
                                                        value={formReq ? formReq.ID_ALM_RQI : ''}
                                                        datos={ctgUcAlmacenes.map((e) => { return { clave: e.ID_ALM, label: e.TITULO_ALM } }).filter(v => esEditar && !rutaPlantillas ? formReq.ID_ALM_RQI === v.clave : v.clave)}
                                                        onChange={(e) => { setFormReq(l => { return { ...l, ID_ALM_RQI: e.item.clave } }) }}
                                                        required
                                                        spellcheck="false"
                                                    />
                                                    <label htmlFor="validationTooltip02"><i class="fa fa-archive" aria-hidden="true"></i> Seleccionar Almacen solicitante<span className="text-danger">*</span></label>
                                                </div>
                                                <span className="text-danger txtVacios clave_almacen" style={{ display: 'none' }}><strong>Falta seleccionar (Almacen solicitante)</strong></span>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <div className='input-field select'>
                                                    <Select
                                                        data-label="almacen surtido"
                                                        id="clave_almacen_surtido"
                                                        name="clave_almacen_surtido"
                                                        defaultOptOff={/*ctgUcAlmacenes.length > 1 &&*/ !(esEditar && !rutaPlantillas) ? false : true}
                                                        value={formReq ? formReq.ID_ALM_SURTIDO_RQI : ''}
                                                        datos={almacenesSur.map((e) => { return { clave: e.ID_ALM, label: e.TITULO_ALM } }).filter(v => esEditar && !rutaPlantillas ? formReq.ID_ALM_SURTIDO_RQI === v.clave : v.clave)}
                                                        onChange={(e) => { setFormReq(l => { return { ...l, ID_ALM_SURTIDO_RQI: e.item.clave } }) }}
                                                        required
                                                        spellcheck="false"
                                                    />
                                                    <label htmlFor="validationTooltip02"><i class="fa fa-archive" aria-hidden="true"></i> Seleccionar Almacen surtido<span className="text-danger">*</span></label>
                                                </div>
                                                <span className="text-danger txtVacios clave_almacen_surtido" style={{ display: 'none' }}><strong>Falta seleccionar (Almacen)</strong></span>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <div className='input-field select'>
                                                    <Select
                                                        data-label="solicitante"
                                                        id="clave_solicitante"
                                                        name="clave_solicitante"
                                                        defaultOptOff={true}
                                                        value={formReq.ID_SOLICITANTE_US_RQI}
                                                        datos={ctgUsuarios.filter(v => v.ID_UC === formReq.ID_SOLICITANTE_US_RQI).map((e) => { return { clave: e.ID_UC, label: `${e.NOMBRES_UC} ${e.APELLIDOS_UC}` } })}
                                                        onChange={(e) => { setFormReq(l => { return { ...l, ID_SOLICITANTE_US_RQI: e.item.clave } }) }}
                                                        required
                                                        spellcheck="false"
                                                    />
                                                    <label htmlFor="validationTooltip02"><i class="fa fa-archive" aria-hidden="true"></i> Seleccionar Solicitante<span className="text-danger">*</span></label>
                                                </div>
                                                <span className="text-danger txtVacios clave_solicitante" style={{ display: 'none' }}><strong>Falta seleccionar (Solicitante)</strong></span>
                                            </div>
                                            {/* <div className="col-md-6 mb-3">
                                                <div className='input-field select'>
                                                    <Select
                                                        data-label="surtido"
                                                        id="clave_surtidor"
                                                        name="clave_surtidor"
                                                        value={formReq ? formReq.ID_SURTIDOR_US_RQI : ''}
                                                        datos={ctgUsuarios.map((e) => { return { clave: e.ID_UC, label: `${e.NOMBRES_UC} ${e.APELLIDOS_UC}` } })}
                                                        onChange={(e) => { setFormReq(l => { return { ...l, ID_SURTIDOR_US_RQI: e.item.clave } }) }}
                                                        required
                                                        spellcheck="false"
                                                    />
                                                    <label htmlFor="validationTooltip02"><i class="fa fa-comment" aria-hidden="true"></i> Seleccionar Surtidor<span className="text-danger">*</span></label>
                                                </div>
                                                <span className="text-danger txtVacios txt_abreviatura" style={{ display: 'none' }}><strong>Falta seleccionar (Surtidor)</strong></span>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <div className='input-field select'>
                                                    <Select
                                                        data-label="Auditor"
                                                        id="clave_auditor"
                                                        name="clave_auditor"
                                                        value={formReq ? formReq.ID_AUDITOR_US_RQI : ''}
                                                        datos={ctgUsuarios.map((e) => { return { clave: e.ID_UC, label: `${e.NOMBRES_UC} ${e.APELLIDOS_UC}` } })}
                                                        onChange={(e) => { setFormReq(l => { return { ...l, ID_AUDITOR_US_RQI: e.item.clave } }) }}
                                                        required
                                                        spellcheck="false"
                                                    />
                                                    <label htmlFor="validationTooltip02"><i class="fa fa-comment" aria-hidden="true"></i> Seleccionar Auditor<span className="text-danger">*</span></label>
                                                </div>
                                                <span className="text-danger txtVacios txt_abreviatura" style={{ display: 'none' }}><strong>Falta seleccionar (Auditor)</strong></span>
                                            </div> */}
                                            {/* <div className="col-md-6 mb-3">
                                                <div className='input-field'>
                                                    <input autoComplete='off'
                                                        data-label="fecha"
                                                        id="txt_fecha" name="txt_fecha"
                                                        type="date"
                                                        value={formReq ? formReq.FECHA_FORMAT : ''}
                                                        onChange={(e) => { setFormReq(l => { return { ...l, FECHA_FORMAT: e.target.value } }) }}
                                                        required
                                                        spellcheck="false"
                                                    />
                                                </div>
                                                <span className="text-danger txtVacios txt_fecha" style={{ display: 'none' }}><strong>Falta completar (Fecha)</strong></span>
                                            </div> */}
                                            <div className="col-md-12 mb-3">
                                                <div className='input-field'>
                                                    <textarea autoComplete='off'
                                                        data-label="Descripcion"
                                                        id="txt_descripcion" name="txt_descripcion"
                                                        type="text"
                                                        value={formReq.COMENTARIOS_RQI}
                                                        onChange={(e) => { setFormReq(l => { return { ...l, COMENTARIOS_RQI: e.target.value } }) }}
                                                        required
                                                        spellcheck="false"
                                                    />
                                                    <label htmlFor="validationTooltip02"><i class="fa fa-comment" aria-hidden="true"></i> Descripción<span className="text-danger">*</span></label>
                                                </div>
                                                <span className="text-danger txtVacios txt_descripcion" style={{ display: 'none' }}><strong>Falta completar (Descripción)</strong></span>
                                            </div>                                            
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 mb-3">
                                                <h5 className='title-card-general'>Autorización</h5>
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                <div className="col-md-12 mb-3">
                                                    <InputLabel
                                                        id="check_autorizar"
                                                        name="check_autorizar"
                                                        checked={formReq.REQUIERE_AUTORIZAR_RQI ? true : false}
                                                        opcion={'Requiere autorización'}
                                                        callback={(checked) => { setFormReq(l => ({ ...l, REQUIERE_AUTORIZAR_RQI: checked })) }}
                                                    />
                                                </div>
                                                <div className="col-md-12 mb-3">
                                                    <div className="justify-content-end d-flex">
                                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                            <button className='btn-nuevo' type='button'
                                                                onClick={() => { setFormPag(!formPag) }}
                                                            >
                                                                <span className="text">Siguiente</span>
                                                                <div className="icon-container">
                                                                    <div className="icon icon--left">
                                                                    </div>
                                                                    <div className="icon icon--right">
                                                                    </div>
                                                                </div>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>)
}

export default Default