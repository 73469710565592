import React, { Fragment, useState, useEffect } from 'react';

const Default = ({ checked, callback, opcion, id }) => {
    const [optCheck, setOptCheck] = useState(checked)

    return <Fragment>
        <label className="d-block container-fluid" for={"chk-ani" + (id ? `-${id}` : '')}>
            <input
                className="checkbox_custom"
                id={"chk-ani" + (id ? `-${id}` : '')}
                type="checkbox"
                checked={optCheck}
                onClick={(e) => {
                    callback(e.target.checked)
                    setOptCheck(e.target.checked)
                }}
            />
            {opcion}
        </label>
    </Fragment>
}

export default Default