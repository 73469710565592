import { Fragment, useState, useEffect } from 'react';
import { Home, Briefcase, BarChart2 } from 'react-feather';
// import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { useNavigate, useParams } from 'react-router-dom'
import { Alert } from 'reactstrap';
import RequisicionTabla from './requisiciones_surtido'
import Requisicion from './requisiciones_surtido_form'
import RequisicionArtIns from './requisiciones_surtido_artins'
// import Breadcrumb from '../../components/common/breadcrumb';
import Breadcrumb from '../common/breadcrumbv2';
import Toast from '../common/tool_toast'
import Tools from '../../js/tools'

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

const Default = ({ esEditar, esPlantilla, rutaPlantillas, plantillaSimple }) => {
    const [parent, setParent] = useState('datos')
    const [verPlantilla, setVerPlantilla] = useState(true)
    const [permisoAlmacen, setPermisoAlmacen] = useState(false)
    const [valueTab, setValueTab] = useState('2');
    const [datosEmpresa, setDatosEmpresa] = useState(null)
    const [ctgUsuarios, setCtgUsuarios] = useState([])
    const [ctgAlmacenes, setCtgAlmacenes] = useState([])
    const [ctgUcAlmacenes, setCtgUcAlmacenes] = useState([])
    const [ctgEstatus, setCtgEstatus] = useState([])
    const [formReq, setFormReq] = useState({})
    const [dataForm, setDataForm] = useState({})
    const [formPag, setFormPag] = useState(true)
    const [listArtIns, setListArtIns] = useState({})
    const [cantidadVersiones, setCantidadVersiones] = useState(false)

    const navigate = useNavigate()

    const valorEstatus = [3, 4, 5]
    const params = useParams()
    const estatus = [
        {
            alert: <Alert color="warning" className="dark">
                Requisición pendiente de autorizar
            </Alert>,
            titulo: 'Requisición pendiente de autorizar'
        },
        {
            alert: <Alert color="info" className="dark">
                Requisición autorizada
            </Alert>,
            titulo: 'Requisición autorizada'
        },
        {
            alert: <Alert color="danger" className="dark">
                Requisición en proceso
            </Alert>,
            titulo: 'Requisición en proceso'
        },
        {
            alert: <Alert color="success" className="dark">
                Requisición completada
            </Alert>,
            titulo: 'Requisición completada'
        },
        {
            alert: <Alert color="primary" className="dark">
                Requisición cancelada
            </Alert>,
            titulo: 'Requisición cancelada'
        },
    ]

    useEffect(() => {
        let isSubscribed = true

        Tools.toolFetch({
            ruta: 'rt_requisiciones_detalle',
            method: 'POST',
            body: params,
        }, (response) => {
            console.log(response);

            const mostrarP = !esPlantilla ? (params.id ? (response.result[0][0].ES_PLANTILLA_RQI || response.result[0][0].NOMBRE_RQI ? true : false) : false) : true

            setFormReq(esEditar ? response.result[0][0] : { REQUIERE_AUTORIZAR_RQI: true })
            setDataForm(plantillaSimple ? { clave_solicitante: response.result[0][0].ID_SOLICITANTE_US_RQI, check_autorizar: true } : {})
            setPermisoAlmacen(response.result[3][0].PERMISO_ALMACEN)
            setListArtIns(l => {
                if (!rutaPlantillas && !esEditar) return {}
                l = {}

                response.result[1].forEach(v => {
                    l[`${v.ID_INA_RQP}_${v.ID_PRV_RQP}`] = {
                        id: v.ID_INA_RQP,
                        id_prv: v.ID_PRV_RQP,
                        cantidad: rutaPlantillas ? v.CANTIDAD_RQP : v.CANTIDAD,
                        cantidad_rqp: v.CANTIDAD_RQP,
                        cantidad_auth: v.CANTIDAD_AUTH_RQP,
                        cantidad_proceso: v.CANTIDAD_PROCESO_RQP,
                        comentarios: v.COMENTARIOS,
                        activo: v.ACTIVO_RQP
                    }
                });
                return l
            })
            setVerPlantilla(mostrarP)
            setValueTab(mostrarP ? '0' : '1')
            setCantidadVersiones(response.result[2][0].CANTIDAD > 0 ? true : false)
        })

        return () => isSubscribed = false
    }, [rutaPlantillas, esPlantilla, esEditar])

    useEffect(() => {
        Tools.toolFetch({
            ruta: 'rt_requisiciones_catalogos',
            method: 'POST',
            body: params,
        }, (response) => {
            if (response.status === 'OK') {
                if (!esEditar) {
                    setFormReq(l => { return { ...l, ID_SOLICITANTE_US_RQI: response.idUs, FECHA_FORMAT: response.fechaFormat } })
                }
                setCtgUsuarios(response.ctgUs)
                setCtgUcAlmacenes(esEditar && !rutaPlantillas ? response.ctgAlm : response.ctgUcAlm)
                setCtgAlmacenes(response.ctgAlm)
                setCtgEstatus(response.ctgEst.filter(e => valorEstatus.includes(e.VALOR_EST)))
            }
        })
    }, [esPlantilla, rutaPlantillas])

    const handleChange = (event, newValue) => {
        console.log(formReq);


        if (newValue === '1') return setFormPag(!formPag)
        setValueTab(newValue);
    };

    return (
        <Fragment>
            <Breadcrumb icon={Home} title='Requisiciones Surtido' parent={[{ titulo: 'requisiciones surtido', link: '/requisiciones-surtido' }, { titulo: `${esEditar ? 'editar' : 'nueva'} ${esPlantilla ? 'plantilla' : 'requisición'}`, link: `/requisiciones-surtido/${esEditar ? params.id : 'nuevo'}` }]} />
            <div className="container-fluid">
                <div className="row theme-tab">
                    <div className="col-sm-12">
                        <div className='card'>
                            <div className="card-header d-flex gap-3 mb-0">
                                <button className='btn-regresar' type='button' onClick={(e) => {
                                    navigate('/requisiciones-surtido')
                                }}>Volver al inicio</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        {!rutaPlantillas && !esPlantilla & esEditar ?
                            <Fragment>
                                {estatus[formReq.ESTATUS_RQI - 1] ? estatus[formReq.ESTATUS_RQI - 1].alert : ''}
                            </Fragment>
                            : ''}
                    </div>
                    {/* <Tabs className="col-sm-12">
                        <TabList className="tabs tab-title">
                            <Tab className="current">
                                <BarChart2 />Datos
                            </Tab>
                            <Tab className="current">
                                <Briefcase />Insumos/Articulos
                            </Tab>
                        </TabList>
                        <div className="tab-content-cls">
                            <TabPanel>
                                <Requisicion listArtIns={listArtIns} formReq={formReq} setFormReq={setFormReq} esEditar={esEditar}
                                    setParent={setParent} />
                            </TabPanel>
                            <TabPanel>
                                <RequisicionArtIns listArtIns={listArtIns} setListArtIns={setListArtIns} esEditar={esEditar}
                                    setParent={setParent} />
                            </TabPanel>
                        </div>
                    </Tabs> */}
                    <Box Box >
                        <TabContext value={valueTab}>
                            <Box>
                                <TabList
                                    TabIndicatorProps={{
                                        style: {
                                            backgroundColor: "#D97D54"
                                        }
                                    }}
                                    style={{ color: "#7E0000" }}
                                    textColor='inherit'
                                    onChange={handleChange}
                                    aria-label="lab API tabs example">
                                    {verPlantilla ? <Tab icon={<BarChart2 />} iconPosition="start" label='Datos' value="0" /> : ''}
                                    <Tab icon={<Briefcase />} iconPosition="start" label='Insumos/Articulos' value="1" />
                                    {esEditar && rutaPlantillas & cantidadVersiones ? <Tab icon={<BarChart2 />} iconPosition="start" label='Historial' value="2" /> : ''}
                                </TabList>
                            </Box>
                            <div className="tab-content-cls">
                                {verPlantilla ?
                                    <TabPanel value="0">
                                        <Requisicion
                                            listArtIns={listArtIns}
                                            formReq={formReq}
                                            setFormReq={setFormReq}
                                            esEditar={esEditar}
                                            setParent={setParent}
                                            formPag={formPag}
                                            setFormPag={setFormPag}
                                            setValueTab={setValueTab}
                                            setDataForm={setDataForm}
                                            esPlantilla={esPlantilla}
                                            rutaPlantillas={rutaPlantillas}
                                            ctgUsuarios={ctgUsuarios}
                                            ctgUcAlmacenes={ctgUcAlmacenes}
                                            ctgAlmacenes={ctgAlmacenes}
                                        />
                                    </TabPanel>
                                    : ''}
                                <TabPanel value="1">
                                    <RequisicionArtIns
                                        listArtIns={listArtIns}
                                        setListArtIns={setListArtIns}
                                        esEditar={esEditar}
                                        setParent={setParent}
                                        setDataForm={setDataForm}
                                        dataForm={dataForm}
                                        formReq={formReq}
                                        esPlantilla={esPlantilla}
                                        rutaPlantillas={rutaPlantillas}
                                        ctgUcAlmacenes={ctgUcAlmacenes}
                                        ctgAlmacenes={ctgAlmacenes}
                                        setCtgAlmacenes={setCtgAlmacenes}
                                        ctgEstatus={ctgEstatus}
                                        setFormReq={setFormReq}
                                        estatus={estatus}
                                        valorEstatus={valorEstatus}
                                        permisoAlmacen={permisoAlmacen}
                                    />
                                </TabPanel>
                                {esEditar && rutaPlantillas & cantidadVersiones ?
                                    <TabPanel value="2">
                                        <RequisicionTabla
                                            esHistorial={true}
                                        />
                                    </TabPanel> : ''
                                }
                            </div>
                        </TabContext>
                    </Box >
                </div >
            </div >
        </Fragment >)
}


export default Default;