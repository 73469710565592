import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Home, Briefcase, BarChart2 } from 'react-feather';
// import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { useNavigate, useParams } from 'react-router-dom'
import DatosProveedor from './proveedor_form'
import DatosProveedorContacto from './proveedores_contacto'
import ProveedorArtIns from './proveedor_artins'
// import Breadcrumb from '../../components/common/breadcrumb';
import Breadcrumb from '../common/breadcrumbv2';
import Toast from '../common/tool_toast'
import Tools from '../../js/tools'
import notie from 'notie'
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

const Default = ({ esEditar }) => {
    let strUrlParent = 'Proveedores'
    const [listArtIns, setListArtIns] = useState({})
    const [formProv, setFormProv] = useState({})
    const [dataProveedores, setDataProveedores] = useState({})
    const [valueTab, setValueTab] = useState('0');
    const [sigPag, setSigPag] = useState('0')
    const [titulo, setTitulo] = useState('')
    const firstCurrent = useRef(true)
    const params = useParams()
    const navigate = useNavigate()

    const handleChange = (event, newValue) => {
        if (['0', '2'].includes(newValue)) {
            setSigPag('0')
            setValueTab(newValue)
            return
        }
        setSigPag(newValue)
    };

    const onClickForm = () => {
        const form = dataProveedores

        form.data.id = params.id
        Tools.toolFetch({
            ruta: !esEditar ? 'rt_proveedores_nuevo' : 'rt_proveedores_editar',
            method: 'POST',
            body: { data: form.data, seleccionados: listArtIns },
        }, (response) => {
            console.log(response)

            if (response.status === 'ERROR') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persiste el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 2, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            setTimeout(() => {
                navigate('/proveedores')
            }, 2000);

            notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Los datos se han guardado correctamente`,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }

    useEffect(() => {
        if (firstCurrent.current) {
            firstCurrent.current = false
            return
        }

        if (sigPag > 0) {
            const form = valueTab === '0' ? Tools.getForm('formProv') : dataProveedores

            if (valueTab === '0') setDataProveedores(form)
            if (form.isEmtyCount > 0) {
                setSigPag('0')
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ingrese los campos restantes`,
                    // stay: Boolean, // optional, default = false
                    time: 2, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }
        }

        if (sigPag > 1) {
            const articulosInsumos = Object.values(listArtIns).filter((v) => v)
            if (articulosInsumos.length === 0) {
                setSigPag('0')
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: 'Ingrese algún Articulo/Insumo',
                    // stay: Boolean, // optional, default = false
                    time: 2, // optional, default = 3, minimum = 1,

                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }
            return onClickForm()
        }

        if (sigPag !== '0') setValueTab(sigPag)
    }, [sigPag])



    useEffect(() => {
        Tools.toolFetch({
            ruta: 'rt_proveedores_detalle',
            method: 'POST',
            body: { id: params.id },
        }, (response) => {
            if (response.status === 'OK') {
                setFormProv(response.result[0][0])
                response.result[1].forEach(v => {
                    setListArtIns(l => {
                        l[v.ID_INA_PIA] = v.ID_INA_PIA
                        return l
                    })
                });

                if (esEditar) setTitulo(response.result[0][0].TITULO_PRV)
            }
        })

        if (!esEditar) {
            setFormProv({})
            setListArtIns({})
            setValueTab('0')
            setSigPag('0')
            return
        }
    }, [esEditar])

    return (
        <Fragment>
            <Breadcrumb icon={Home} title={strUrlParent} parent={[{ titulo: 'proveedores', link: '/proveedores' }, { titulo: `${esEditar ? 'editar' : 'nuevo'} proveedores`, link: `/proveedores/${esEditar ? params.id : 'nuevo'}` }]} />
            <div className="container-fluid">
                <div className="row theme-tab">
                    {/* <Tabs className="col-sm-12">
                        <TabList className="tabs tab-title">
                            <Tab className="current">
                                <BarChart2 />Datos
                            </Tab>
                            <Tab className="current">
                                <BarChart2 />Insumos/Articulos
                            </Tab>
                            {esEditar ?
                                <Tab className="current">
                                    <Briefcase />Contactos
                                </Tab> : ''}
                        </TabList>
                        <div className="tab-content-cls">
                            <TabPanel>
                                <DatosProveedor esEditar={esEditar} formProv={formProv} setFormProv={setFormProv} listArtIns={listArtIns} />
                            </TabPanel>
                            <TabPanel>
                                <ProveedorArtIns listArtIns={listArtIns} setListArtIns={setListArtIns} />
                            </TabPanel>
                            {esEditar ?
                                <TabPanel>
                                    <DatosProveedorContacto />
                                </TabPanel> : ''}
                        </div>
                    </Tabs> */}
                    <div className="col-sm-12">
                        <div className='card mb-0'>
                            <div className="card-header d-flex gap-3 mb-0">
                                <button className='btn-regresar' type='button' onClick={(e) => {
                                    navigate('/proveedores')
                                }}>Volver al inicio</button>
                            </div>
                        </div>
                    </div>
                    <Box>
                        <TabContext value={valueTab}>
                            <Box>
                                <TabList
                                    TabIndicatorProps={{
                                        style: {
                                            backgroundColor: "#D97D54"
                                        }
                                    }}
                                    style={{ color: "#7E0000" }}
                                    textColor='inherit'
                                    onChange={handleChange}
                                    aria-label="lab API tabs example">
                                    <Tab icon={<BarChart2 />} iconPosition="start" label='Datos' value="0" />
                                    <Tab icon={<BarChart2 />} iconPosition="start" label='Insumos/Articulos' value="1" />
                                    {esEditar ? <Tab icon={<Briefcase />} iconPosition="start" label='Contactos' value="2" /> : ''}
                                </TabList>
                            </Box>
                            <div className="tab-content-cls">
                                <TabPanel value="0">
                                    <DatosProveedor
                                        esEditar={esEditar}
                                        formProv={formProv}
                                        setFormProv={setFormProv}
                                        listArtIns={listArtIns}
                                        setSigPag={setSigPag}
                                        setDataProveedores={setDataProveedores}
                                    />
                                </TabPanel>
                                <TabPanel value="1">
                                    <ProveedorArtIns
                                        listArtIns={listArtIns}
                                        setListArtIns={setListArtIns}
                                        setSigPag={setSigPag}
                                        titulo={titulo}
                                    />
                                </TabPanel>
                                {esEditar ?
                                    <TabPanel value="2">
                                        <DatosProveedorContacto
                                            titulo={titulo}
                                        />
                                    </TabPanel> : ''}
                            </div>
                        </TabContext>
                    </Box>
                </div>
            </div>
        </Fragment>
    )
}


export default Default;