import React, { Fragment, useState, useEffect, useRef } from 'react';
import Select from '../common/select-basic'
import Tools from '../../js/tools'
import notie from 'notie'
import { Briefcase } from 'react-feather';
import Breadcrumb from '../common/breadcrumbv2';
import { useNavigate, useParams, useLocation } from 'react-router-dom'
// import { GetPermisos } from '../common/getPermisos';
import Dropzone from '../common/dropzone';
import NewDropzone from '../common/dropzone2';
import SelectFecth from '../common/select-fetch';
import SelectSimple from '../common/select-simple';
import { NumericFormat } from 'react-number-format'
import TablaPrecios from '../common/tabla_estatica';
import { GetPermisos } from '../common/getPermisos';

const Default = ({
    esEditar,
    listArtIns,
    setListArtIns,
    formRec,
    setFormRec,
    procedimiento,
    setProcedimiento,
    setValueTab,
    listUnidadMedida,
    img,
    setImg,
    formPag,
    primerClick,
    setPrimerClick,
    setFormPag,
    setDataForm,
    listFamilias,
    listPresentaciones,
    presentaciones,
    setPresentaciones,
    esHistorial }) => {
    const [verify, setVerify] = useState(true)
    const [tipoVal, setTipoVal] = useState(0)
    const [ctgUsuarios, setCtgUsuarios] = useState([])
    const [ctgAlmacenes, setCtgAlmacenes] = useState([])
    const [listSelect, setListSelect] = useState({})
    const navigate = useNavigate()
    const params = useParams();
    const firstCurrent = useRef(true)
    const location = useLocation()

    useEffect(() => {
        if (firstCurrent.current) {
            firstCurrent.current = false
            return
        }

        const form = siguientePag()
        if (form.activo) {
            setValueTab('1')
            setDataForm(form.form)
        }
    }, [formPag])

    const siguientePag = () => {
        const form = Tools.getForm('formRec', { ignore: ['txt_descripcion'] })
        let presentacionesEmpty = false
        if (esHistorial) return { activo: 1, form }

        presentaciones.forEach(v => { if (Object.keys(v).map(e => v[e]).filter(s => !s).length) presentacionesEmpty = true })

        if (form.isEmtyCount > 0) {
            notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Ingrese los campos: ` + form.isEmtyString,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
            return { activo: 0 }
        }

        if (!img.imgPortada) {
            notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Ingrese una imagen`,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
            return { activo: 0 }
        }

        if (presentaciones.length === 0) {
            notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: 'Ingrese por lo menos una presentación',
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
            return { activo: 0 }
        }

        if (presentacionesEmpty) {
            notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Ingrese todos los campos de las presentaciones`,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
            return { activo: 0 }
        }

        if ((presentaciones.filter(v => v.value > 0)).length !== presentaciones.length) {
            notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: 'Seleccione todas las presentaciones',
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
            return { activo: 0 }
        }

        if (procedimiento.length === 0) {
            notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Ingrese un procedimiento para su receta.`,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
            return { activo: 0 }
        }

        let imgType = img.imgPortada.type.split('/')
        if (!['jpg', 'jpeg', 'png'].includes(imgType[imgType.length - 1])) {
            notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Inserte un archivo .jpg, .jpeg, .png`,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
            return { activo: 0 }
        }

        if (img.imgPortada.size > 5767168) {
            notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Su imagen excede el limite (5mb)`,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
            return { activo: 0 }
        }

        return { activo: 1, form }
    }


    const changeCompraArtIns = (compra_final) => {
        compra_final = compra_final ? compra_final : 0

        const listJson = {}
        const lista = Object.values(listArtIns).filter((v) => v)

        lista.forEach(v => {
            let inv_in = parseFloat(v.inv_in ? v.inv_in : 0)
            let merma = parseFloat(v.merma ? v.merma : 0)
            let compra = parseFloat(v.compra ? v.compra : 0)
            let produccion = parseFloat(v.produccion ? v.produccion : 0)
            let consumo = produccion * compra_final

            listJson[v.id] = { ...v, consumo, inv_fin: (inv_in + compra - merma - consumo) }
        })

        setListArtIns(listJson)
    }

    return (
        <Fragment>
            {/* <Breadcrumb icon={Briefcase} title={'Familias insumos/articulos'} parent={['familias insumos']} /> */}
            <div className="container-fluid ">
                <div className='row'>
                    <form id='formRec' className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className='card'>
                            {/* <div className="card-header d-flex justify-content-between">

                                <button className='btn-regresar' type='button' onClick={(e) => {
                                    navigate('/familias-insumos')
                                }}></button>
                                <h4><strong>{esEditar ? 'EDITAR' : 'NUEVA'} FAMILA DE INSUMOS/ARTICULOS</strong></h4>
                                <h4></h4>
                            </div> */}
                            <div className="card-body">
                                <div className='row'>
                                    <div className="col-12">
                                        <div className="row mb-5">
                                            <div class="col-md-12 mb-3">
                                                <h5 className='title-card-general'>Datos generales de la receta</h5>
                                            </div>
                                            {/* <div className="col-md-6 mb-3">
                                                <div className='input-field'>
                                                    <input autoComplete='off'
                                                        data-label="clave interna"
                                                        id="txt_clave" name="txt_clave"
                                                        type="text"
                                                        value={formRec.CLAVE_INTERNA_RE ? formRec.CLAVE_INTERNA_RE : ''}
                                                        onChange={(e) => { setFormRec(f => { return { ...f, CLAVE_INTERNA_RE: e.target.value } }) }}
                                                        required
                                                        spellcheck="false"
                                                    />
                                                    <label htmlFor="validationTooltip02"><span class="icon-icono_Materia-Prima"></span> Clave interna<span className="text-danger">*</span></label>
                                                </div>
                                                <span className="text-danger txtVacios txt_clave" style={{ display: 'none' }}><strong>Falta completar (Clave interna)</strong></span>
                                            </div> */}
                                            {/* <div className="col-md-6 mb-3">
                                                <div className='input-field'>
                                                    <input autoComplete='off'
                                                        data-label="clave interna"
                                                        id="txt_sku" name="txt_sku"
                                                        type="text"
                                                        value={formRec.SKU_RE ? formRec.SKU_RE : ''}
                                                        onChange={(e) => { setFormRec(f => { return { ...f, SKU_RE: e.target.value } }) }}
                                                        required
                                                        spellcheck="false"
                                                    />
                                                    <label htmlFor="validationTooltip02"><span class="icon-icono_Materia-Prima"></span> Sku<span className="text-danger">*</span></label>
                                                </div>
                                                <span className="text-danger txtVacios txt_sku" style={{ display: 'none' }}><strong>Falta completar (Sku)</strong></span>
                                            </div> */}
                                            <div className="col-md-6 mb-3">
                                                <div className='input-field'>
                                                    <input autoComplete='off'
                                                        data-label="titulo"
                                                        id="txt_titulo" name="txt_titulo"
                                                        type="text"
                                                        maxLength={150}
                                                        value={formRec.TITULO_RE ? formRec.TITULO_RE : ''}
                                                        onChange={(e) => {
                                                            if (esHistorial) return
                                                            setFormRec(f => { return { ...f, TITULO_RE: e.target.value } })
                                                        }}
                                                        required
                                                        spellcheck="false"
                                                    />
                                                    <label htmlFor="validationTooltip02"><i class="fa fa-tags" aria-hidden="true"></i> Título<span className="text-danger">*</span></label>
                                                </div>
                                                <span className="text-danger txtVacios txt_titulo" style={{ display: 'none' }}><strong>Falta completar (Título)</strong></span>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <div className='input-field'>
                                                    <input autoComplete='off'
                                                        data-label="nombre"
                                                        id="txt_nombre" name="txt_nombre"
                                                        type="text"
                                                        maxLength={150}
                                                        value={formRec.NOMBRE_RE ? formRec.NOMBRE_RE : ''}
                                                        onChange={(e) => {
                                                            if (esHistorial) return
                                                            setFormRec(f => { return { ...f, NOMBRE_RE: e.target.value } })
                                                        }}
                                                        required
                                                        spellcheck="false"
                                                    />
                                                    <label htmlFor="validationTooltip02"><i class="fa fa-book" aria-hidden="true"></i> Nombre<span className="text-danger">*</span></label>
                                                </div>
                                                <span className="text-danger txtVacios txt_nombre" style={{ display: 'none' }}><strong>Falta completar (Nombre)</strong></span>
                                            </div>
                                            {/* <div className="col-md-6 mb-3">
                                                <div className='input-field'>
                                                    <input autoComplete='off'
                                                        data-label="Cantidad minima"
                                                        id="txt_cantidad_min" name="txt_cantidad_min"
                                                        type="number"
                                                        maxLength={150}
                                                        value={formRec.CANTIDAD_MIN_RE ? formRec.CANTIDAD_MIN_RE : ''}
                                                        onChange={(e) => {
                                                            if (esHistorial) return

                                                            let valor = e.target.value
                                                            if (valor < 0) valor = 0
                                                            if (valor > 9999999) return

                                                            setFormRec(f => { return { ...f, CANTIDAD_MIN_RE: valor } })
                                                        }}
                                                        required
                                                        spellcheck="false"
                                                    />
                                                    <label htmlFor="validationTooltip02"><i class="fa fa-level-down" aria-hidden="true"></i> Cantidad minima<span className="text-danger">*</span></label>
                                                </div>
                                                <span className="text-danger txtVacios txt_cantidad_min" style={{ display: 'none' }}><strong>Falta completar (Cantidad minima)</strong></span>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <div className='input-field select'>
                                                    <Select
                                                        defaultOptOff={esHistorial}
                                                        data-label="Unidad minima"
                                                        id="clave_cantidad_min"
                                                        name="clave_cantidad_min"
                                                        value={formRec ? formRec.MIN_CU_RE : ''}
                                                        datos={listUnidadMedida.filter(v => esHistorial ? v.clave === formRec.MIN_CU_RE : v)}
                                                        onChange={(e) => { setFormRec(l => { return { ...l, MIN_CU_RE: e.item.clave } }) }}
                                                        required
                                                        spellcheck="false"
                                                    />
                                                    <label htmlFor="validationTooltip02"><i class="fa fa-level-down" aria-hidden="true"></i> Seleccionar una Unidad minima<span className="text-danger">*</span></label>
                                                </div>
                                                <span className="text-danger txtVacios clave_cantidad_min" style={{ display: 'none' }}><strong>Falta seleccionar (Unidad minima)</strong></span>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <div className='input-field'>
                                                    <input autoComplete='off'
                                                        data-label="Cantidad maxima"
                                                        id="txt_cantidad_max" name="txt_cantidad_max"
                                                        type="number"
                                                        maxLength={150}
                                                        value={formRec.CANTIDAD_MAX_RE ? formRec.CANTIDAD_MAX_RE : ''}
                                                        onChange={(e) => {
                                                            if (esHistorial) return

                                                            let valor = e.target.value
                                                            if (valor < 0) valor = 0
                                                            if (valor > 9999999) return

                                                            setFormRec(f => { return { ...f, CANTIDAD_MAX_RE: valor } })
                                                        }}
                                                        required
                                                        spellcheck="false"
                                                    />
                                                    <label htmlFor="validationTooltip02"><i class="fa fa-level-up" aria-hidden="true"></i> Cantidad maxima<span className="text-danger">*</span></label>
                                                </div>
                                                <span className="text-danger txtVacios txt_cantidad_max" style={{ display: 'none' }}><strong>Falta completar (Cantidad maxima)</strong></span>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <div className='input-field select'>
                                                    <Select
                                                        defaultOptOff={esHistorial}
                                                        data-label="Unidad maxima"
                                                        id="clave_cantidad_max"
                                                        name="clave_cantidad_max"
                                                        value={formRec ? formRec.MAX_CU_RE : ''}
                                                        datos={listUnidadMedida.filter(v => esHistorial ? v.clave === formRec.MAX_CU_RE : v)}
                                                        onChange={(e) => { setFormRec(l => { return { ...l, MAX_CU_RE: e.item.clave } }) }}
                                                        required
                                                        spellcheck="false"
                                                    />
                                                    <label htmlFor="validationTooltip02"><i class="fa fa-level-up" aria-hidden="true"></i> Seleccionar una Unidad maxima<span className="text-danger">*</span></label>
                                                </div>
                                                <span className="text-danger txtVacios clave_cantidad_max" style={{ display: 'none' }}><strong>Falta seleccionar (Unidad maxima)</strong></span>
                                            </div> */}
                                            <div className="col-md-6 mb-3">
                                                <div className='input-field select'>
                                                    <Select
                                                        defaultOptOff={esHistorial}
                                                        data-label="Familia"
                                                        id="clave_familia"
                                                        name="clave_familia"
                                                        value={formRec ? formRec.ID_FI_RE : ''}
                                                        datos={listFamilias.filter(v => esHistorial ? v.clave === formRec.ID_FI_RE : v)}
                                                        onChange={(e) => { setFormRec(l => { return { ...l, ID_FI_RE: e.item.clave } }) }}
                                                        required
                                                        spellcheck="false"
                                                    />
                                                    <label htmlFor="validationTooltip02"><i class="fa fa-list" aria-hidden="true"></i> Seleccionar una familia<span className="text-danger">*</span></label>
                                                </div>
                                                <span className="text-danger txtVacios clave_familia" style={{ display: 'none' }}><strong>Falta seleccionar (Familia)</strong></span>
                                            </div>
                                            {/* <div className="col-md-3 mb-3">
                                                <div className='input-field'>
                                                    <input autoComplete='off'
                                                        data-label="inventario inicial"
                                                        id="txt_inv_inc" name="txt_inv_inc"
                                                        type="text"
                                                        value={formRec.INV_INC_RE ? formRec.INV_INC_RE : ''}
                                                        onChange={(e) => {
                                                            setFormRec(f => { return { ...f, INV_INC_RE: parseFloat(e.target.value) } })
                                                            setFormRec(f => { return { ...f, INV_FIN_RE: (parseFloat(e.target.value) + f.PALEY1_RE + f.PALEY2_RE - f.MERMA_RE - f.CONSUMO_RE) } })
                                                        }}
                                                        required
                                                        spellcheck="false"
                                                    />
                                                    <label htmlFor="validationTooltip02"><i class="fa fa-comment" aria-hidden="true"></i> Inventario inicial<span className="text-danger">*</span></label>
                                                </div>
                                                <span className="text-danger txtVacios txt_inv_inc" style={{ display: 'none' }}><strong>Falta completar (Inventario inicial)</strong></span>
                                            </div>
                                            <div className="col-md-3 mb-3">
                                                <div className='input-field'>
                                                    <input autoComplete='off'
                                                        data-label="Compra"
                                                        id="txt_compra" name="txt_compra"
                                                        type="text"
                                                        value={formRec.COMPRA_RE ? formRec.COMPRA_RE : ''}
                                                        onChange={(e) => { setFormRec(f => { return { ...f, COMPRA_RE: parseFloat(e.target.value) } }) }}
                                                        required
                                                        spellcheck="false"
                                                    />
                                                    <label htmlFor="validationTooltip02"><i class="fa fa-comment" aria-hidden="true"></i> Compra<span className="text-danger">*</span></label>
                                                </div>
                                                <span className="text-danger txtVacios txt_compra" style={{ display: 'none' }}><strong>Falta completar (compra)</strong></span>
                                            </div>
                                            <div className="col-md-3 mb-3">
                                                <div className='input-field'>
                                                    <input autoComplete='off'
                                                        data-label="produccion"
                                                        id="txt_produccion" name="txt_produccion"
                                                        type="text"
                                                        value={formRec.PRODUCCION_RE ? formRec.PRODUCCION_RE : ''}
                                                        onChange={(e) => {
                                                            setFormRec(f => {
                                                                const compra = f.CONSUMO_RE / parseFloat(e.target.value)
                                                                changeCompraArtIns(compra)

                                                                return { ...f, COMPRA_RE: compra, PRODUCCION_RE: e.target.value }
                                                            })
                                                        }}
                                                        required
                                                        spellcheck="false"
                                                    />
                                                    <label htmlFor="validationTooltip02"><i class="fa fa-comment" aria-hidden="true"></i> Produccion<span className="text-danger">*</span></label>
                                                </div>
                                                <span className="text-danger txtVacios txt_produccion" style={{ display: 'none' }}><strong>Falta completar (Produccion)</strong></span>
                                            </div>
                                            <div className="col-md-3 mb-3">
                                                <div className='input-field'>
                                                    <input autoComplete='off'
                                                        data-label="merma"
                                                        id="txt_merma" name="txt_merma"
                                                        type="text"
                                                        value={formRec.MERMA_RE ? formRec.MERMA_RE : ''}
                                                        onChange={(e) => {
                                                            setFormRec(f => {
                                                                return {
                                                                    ...f,
                                                                    MERMA_RE: parseFloat(e.target.value),
                                                                    INV_FIN_RE: (f.PALEY1_RE + f.PALEY2_RE + f.INV_INC_RE - f.CONSUMO_RE - parseFloat(e.target.value))
                                                                }
                                                            })
                                                        }}
                                                        required
                                                        spellcheck="false"
                                                    />
                                                    <label htmlFor="validationTooltip02"><i class="fa fa-comment" aria-hidden="true"></i> Merma<span className="text-danger">*</span></label>
                                                </div>
                                                <span className="text-danger txtVacios txt_merma" style={{ display: 'none' }}><strong>Falta completar (Merma)</strong></span>
                                            </div>
                                            <div className="col-md-3 mb-3">
                                                <div className='input-field'>
                                                    <input autoComplete='off'
                                                        data-label="paley1"
                                                        id="txt_paley1" name="txt_paley1"
                                                        type="text"
                                                        value={formRec.PALEY1_RE ? formRec.PALEY1_RE : ''}
                                                        onChange={(e) => {
                                                            setFormRec(f => {
                                                                const consumo = ((f.PALEY2_RE ? f.PALEY2_RE : 0) + parseFloat(e.target.value))
                                                                const compra = f.PRODUCCION_RE ? (consumo / f.PRODUCCION_RE) : 0
                                                                changeCompraArtIns(compra)

                                                                return {
                                                                    ...f,
                                                                    CONSUMO_RE: consumo,
                                                                    PALEY1_RE: parseFloat(e.target.value),
                                                                    COMPRA_RE: compra,
                                                                    INV_FIN_RE: (parseFloat(e.target.value) + f.PALEY2_RE - f.MERMA_RE + f.INV_INC_RE + consumo)
                                                                }
                                                            })
                                                        }}
                                                        required
                                                        spellcheck="false"
                                                    />
                                                    <label htmlFor="validationTooltip02"><i class="fa fa-comment" aria-hidden="true"></i> Paley1<span className="text-danger">*</span></label>
                                                </div>
                                                <span className="text-danger txtVacios txt_paley1" style={{ display: 'none' }}><strong>Falta completar (Paley1)</strong></span>
                                            </div>
                                            <div className="col-md-3 mb-3">
                                                <div className='input-field'>
                                                    <input autoComplete='off'
                                                        data-label="paley2"
                                                        id="txt_paley2" name="txt_paley2"
                                                        type="text"
                                                        value={formRec.PALEY2_RE ? formRec.PALEY2_RE : ''}
                                                        onChange={(e) => {
                                                            setFormRec(f => {
                                                                const consumo = ((f.PALEY1_RE ? f.PALEY1_RE : 0) + parseFloat(e.target.value))
                                                                const compra = f.PRODUCCION_RE ? (consumo / f.PRODUCCION_RE) : 0
                                                                changeCompraArtIns(compra)

                                                                return {
                                                                    ...f,
                                                                    CONSUMO_RE: consumo,
                                                                    PALEY2_RE: parseFloat(e.target.value),
                                                                    COMPRA_RE: compra,
                                                                    INV_FIN_RE: (parseFloat(e.target.value) + f.PALEY1_RE - f.MERMA_RE + f.INV_INC_RE + consumo)
                                                                }
                                                            })
                                                        }}
                                                        required
                                                        spellcheck="false"
                                                    />
                                                    <label htmlFor="validationTooltip02"><i class="fa fa-comment" aria-hidden="true"></i> Paley2<span className="text-danger">*</span></label>
                                                </div>
                                                <span className="text-danger txtVacios txt_paley2" style={{ display: 'none' }}><strong>Falta completar (Paley2)</strong></span>
                                            </div>
                                            <div className="col-md-3 mb-3">
                                                <div className='input-field'>
                                                    <input autoComplete='off'
                                                        data-label="consumo"
                                                        id="txt_consumo" name="txt_consumo"
                                                        type="text"
                                                        value={formRec.CONSUMO_RE ? formRec.CONSUMO_RE : ''}
                                                        onChange={(e) => {
                                                            setFormRec(f => {
                                                                const compra = (parseFloat(e.target.value) / (f.PRODUCCION_RE ? f.PRODUCCION_RE : 0))
                                                                changeCompraArtIns(compra)

                                                                return {
                                                                    ...f,
                                                                    COMPRA_RE: compra,
                                                                    CONSUMO_RE: parseFloat(e.target.value),
                                                                    INV_FIN_RE: (f.PALEY1_RE - f.MERMA_RE + f.INV_INC_RE + f.PALEY2_RE - parseFloat(e.target.value))
                                                                }
                                                            })
                                                        }}
                                                        required
                                                        spellcheck="false"
                                                    />
                                                    <label htmlFor="validationTooltip02"><i class="fa fa-comment" aria-hidden="true"></i> Consumo<span className="text-danger">*</span></label>
                                                </div>
                                                <span className="text-danger txtVacios txt_consumo" style={{ display: 'none' }}><strong>Falta completar (Consumo)</strong></span>
                                            </div>
                                            <div className="col-md-3 mb-3">
                                                <div className='input-field'>
                                                    <input autoComplete='off'
                                                        data-label="inventario final"
                                                        id="txt_inv_final" name="txt_inv_final"
                                                        type="text"
                                                        value={formRec.INV_FIN_RE ? formRec.INV_FIN_RE : ''}
                                                        onChange={(e) => { setFormRec(f => { return { ...f, INV_FIN_RE: e.target.value } }) }}
                                                        required
                                                        spellcheck="false"
                                                    />
                                                    <label htmlFor="validationTooltip02"><i class="fa fa-comment" aria-hidden="true"></i> Inventario final<span className="text-danger">*</span></label>
                                                </div>
                                                <span className="text-danger txtVacios txt_inv_final" style={{ display: 'none' }}><strong>Falta completar (Inventario final)</strong></span>
                                            </div> */}
                                            <div className="col-md-12 mb-3">
                                                <div className='input-field'>
                                                    <textarea autoComplete='off'
                                                        data-label="Descripcion"
                                                        id="txt_descripcion" name="txt_descripcion"
                                                        type="text"
                                                        maxLength={1000}
                                                        value={formRec.DESCRIPCION_RE ? formRec.DESCRIPCION_RE : ''}
                                                        onChange={(e) => {
                                                            if (esHistorial) return
                                                            setFormRec(f => { return { ...f, DESCRIPCION_RE: e.target.value } })
                                                        }}
                                                        required
                                                        spellcheck="false"
                                                    />
                                                    <label htmlFor="validationTooltip02"><i class="fa fa-comment" aria-hidden="true"></i> Descripción</label>
                                                </div>
                                                <span className="text-danger txtVacios txt_descripcion" style={{ display: 'none' }}><strong>Falta completar (Descripción)</strong></span>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label className="form-label labelupload">Portada (600 X 400)<span className="text-danger">*</span></label>
                                                <div className="input-group">
                                                    <div className="input-group-prepend" placeholder='Subir archivo'></div>
                                                    {esEditar ?
                                                        <Dropzone
                                                            initialFiles={formRec.ruta_imagen}
                                                            fileName={formRec.ruta_imagen}
                                                            canRemove={!esHistorial}
                                                            inputText={'Da click o arrastra una imagen .jpg, .jpeg, .png (5mb)...'}
                                                            onImg={(datos) => { setImg((s) => ({ ...s, statusPortada: datos.status, imgPortada: datos.file })) }}
                                                        /> :
                                                        <NewDropzone
                                                            initialFiles={null}
                                                            fileName={''}
                                                            canRemove={!esHistorial}
                                                            inputText={'Da click o arrastra una imagen .jpg, .jpeg, .png (5mb)...'}
                                                            onImg={(datos) => { setImg((s) => ({ ...s, statusPortada: datos.status, imgPortada: datos.file })) }}
                                                        />
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                {/* <div className="justify-content-end d-flex">
                                                    {(esEditar && (GetPermisos.find(permiso => [2061].includes(permiso)) ? true : false)) ||
                                                        (!esEditar && (GetPermisos.find(permiso => [31].includes(permiso)) ? true : false)) ?
                                                        <button className='btn-nuevo' type='button' onClick={onClickForm}>
                                                            <span className="text">Guardar</span>
                                                            <div className="icon-container">
                                                                <div className="icon icon--left">
                                                                </div>
                                                                <div className="icon icon--right">
                                                                </div>
                                                            </div>
                                                        </button>
                                                        : ''}
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div class="col-md-12 mb-3">
                                                <h5 className='title-card-general'>Presentaciones</h5>
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                {!esHistorial ?
                                                    <button
                                                        className='btn-nuevo'
                                                        type='button'
                                                        onClick={() => {
                                                            if (presentaciones >= listPresentaciones) {
                                                                return notie.alert({
                                                                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                                                                    text: `Solo es posible ingresar hasta maximo ${listPresentaciones.length} presentaciones`,
                                                                    // stay: Boolean, // optional, default = false
                                                                    time: 2, // optional, default = 3, minimum = 1,
                                                                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                                                                })
                                                            }
                                                            const claves = presentaciones.filter(v => v.value !== 0).map(v => v.value)

                                                            setPresentaciones(l => ([...l, {
                                                                list: listPresentaciones.map(s => ({ ...s, activo: !claves.includes(s.clave) ? true : false })), value: 0, cantidad: 0, unidad: 0, precio: 0, cantidad_max: 0,
                                                                cantidad_min: 0, unidad_max: 0, unidad_min: 0
                                                            }]))
                                                        }}
                                                    >
                                                        <span className="text">Agregar presentación +</span>
                                                    </button>
                                                    : ''
                                                }
                                                <br />
                                                <div className="u-steps u-steps-vertical">
                                                    <div className="table-responsive" style={{ overflowX: 'auto', maxWidth: '100%' }}>
                                                        <table className="table table-bordered table-striped text-center">
                                                            <thead className="thead-dark">
                                                                <tr>
                                                                    <th className="align-middle text-center">Presentación</th>
                                                                    <th className="align-middle text-center">Producción Esperada</th>
                                                                    <th className="align-middle text-center">Presentación</th>
                                                                    <th className="align-middle text-center">Cantidad Mín. Esperada</th>
                                                                    <th className="align-middle text-center">Presentación</th>
                                                                    <th className="align-middle text-center">Cantidad Máx. Esperada</th>
                                                                    <th className="align-middle text-center">Presentación</th>
                                                                    <th className="align-middle text-center">Precio</th>
                                                                    {!esHistorial ? <th className="align-middle text-center" style={{ width: '5%' }}>Acción</th> : ''}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {presentaciones.map((v, index) =>
                                                                    <tr>
                                                                        {/* Presentación */}
                                                                        <td>
                                                                            <Select
                                                                                defaultOptOff={esHistorial}
                                                                                data-label="presentacion"
                                                                                disabled={esHistorial}
                                                                                style={{ minWidth: '180px', ...(primerClick && !v.value ? { borderColor: 'red' } : {}) }}
                                                                                id={"clave_presentacion-" + index}
                                                                                // name={"clave_presentacion-" + index}
                                                                                value={v.value}
                                                                                datos={v.list.filter(v => v.activo)}
                                                                                onChange={(e) => {
                                                                                    const actual = presentaciones[index].value
                                                                                    const claves = [...presentaciones.filter(v => v.value !== 0 && actual !== v.value).map(v => v.value), e.item.clave]

                                                                                    const _rows = presentaciones.map((v, i) => {
                                                                                        if (i === index) return v

                                                                                        const options = v.list.map(s => ({
                                                                                            ...s,
                                                                                            activo: !claves.includes(s.clave) || v.value === s.clave ? true : false
                                                                                        }))

                                                                                        return { ...v, list: options }
                                                                                    })

                                                                                    _rows[index] = { ..._rows[index], value: e.item.clave }
                                                                                    setPresentaciones(_rows)
                                                                                }}
                                                                                required
                                                                                spellcheck="false"
                                                                            />
                                                                        </td>
                                                                        {/* Producción Esperada */}
                                                                        <td>
                                                                            <input autoComplete='off'
                                                                                data-label="Preparación"
                                                                                id={"txt_cantidad_" + index}
                                                                                // name={"txt_cantidad_" + index}
                                                                                type="text"
                                                                                placeholder="Cantidad."
                                                                                className="form-control"
                                                                                disabled={esHistorial}
                                                                                style={{ minWidth: '130px', ...(primerClick && !v.cantidad ? { borderColor: 'red' } : {}) }}
                                                                                maxLength={10}
                                                                                value={v.cantidad}
                                                                                onChange={(e) => {
                                                                                    if (isNaN(Number(e.target.value))) return

                                                                                    const _row = presentaciones.map((v, i) => (i === index ? { ...v, cantidad: e.target.value } : v))

                                                                                    setPresentaciones(_row)
                                                                                }}
                                                                                required
                                                                                spellcheck="false"
                                                                            />
                                                                        </td>
                                                                        {/* Presentación */}
                                                                        <td>
                                                                            <Select
                                                                                defaultOptOff={esHistorial}
                                                                                style={{ minWidth: '180px', ...(primerClick && !v.unidad ? { borderColor: 'red' } : {}) }}
                                                                                disabled={esHistorial}
                                                                                className="form-control"
                                                                                placeholder="Cantidad Min."
                                                                                id={"clave_presentacion_" + index}
                                                                                // name={"clave_presentacion_" + index}
                                                                                value={v.unidad}
                                                                                datos={listUnidadMedida}
                                                                                onChange={(e) => {
                                                                                    const _row = presentaciones.map((v, i) => (i === index ? { ...v, unidad: e.item.clave } : v))

                                                                                    setPresentaciones(_row)
                                                                                }}
                                                                                required
                                                                                spellcheck="false"
                                                                            />
                                                                        </td>
                                                                        {/* Cantidad Mín. Esperada */}
                                                                        <td>
                                                                            <input autoComplete='off'
                                                                                data-label="Preparación"
                                                                                id={"txt_cantidad_presentacion_min_" + index}
                                                                                disabled={esHistorial}
                                                                                // name={"txt_cantidad_presentacion_min_" + index}
                                                                                type="number"
                                                                                placeholder="Cantidad Min."
                                                                                className="form-control"
                                                                                style={{ minWidth: '130px', ...(primerClick && !v.cantidad_min ? { borderColor: 'red' } : {}) }}
                                                                                maxLength={10}
                                                                                value={v.cantidad_min}
                                                                                onChange={(e) => {
                                                                                    if (isNaN(Number(e.target.value))) return

                                                                                    const _row = presentaciones.map((v, i) => (i === index ? { ...v, cantidad_min: e.target.value } : v))

                                                                                    setPresentaciones(_row)
                                                                                }}
                                                                                required
                                                                                spellcheck="false"
                                                                            />
                                                                        </td>
                                                                        {/* Presentación */}
                                                                        <td>
                                                                            <Select
                                                                                defaultOptOff={esHistorial}
                                                                                style={{ minWidth: '180px', ...(primerClick && !v.unidad_min ? { borderColor: 'red' } : {}) }}
                                                                                disabled={esHistorial}
                                                                                className="form-control"
                                                                                placeholder="Cantidad Min."
                                                                                id={"clave_presentacion_min_" + index}
                                                                                // name={"clave_presentacion_min_" + index}
                                                                                value={v.unidad_min}
                                                                                datos={listUnidadMedida}
                                                                                onChange={(e) => {
                                                                                    const _row = presentaciones.map((v, i) => (i === index ? { ...v, unidad_min: e.item.clave } : v))

                                                                                    setPresentaciones(_row)
                                                                                }}
                                                                                required
                                                                                spellcheck="false"
                                                                            />
                                                                        </td>
                                                                        {/* Cantidad Máx. Esperada */}
                                                                        <td>
                                                                            <input autoComplete='off'
                                                                                data-label="Preparación"
                                                                                id={"txt_cantidad_presentacion_max_" + index}
                                                                                // name={"txt_cantidad_presentacion_max_" + index}
                                                                                disabled={esHistorial}
                                                                                type="number"
                                                                                style={{ minWidth: '130px', ...(primerClick && !v.cantidad_max ? { borderColor: 'red' } : {}) }}
                                                                                className="form-control"
                                                                                placeholder="Cantidad Máx."
                                                                                maxLength={10}
                                                                                value={v.cantidad_max}
                                                                                onChange={(e) => {
                                                                                    if (isNaN(Number(e.target.value))) return

                                                                                    const _row = presentaciones.map((v, i) => (i === index ? { ...v, cantidad_max: e.target.value } : v))

                                                                                    setPresentaciones(_row)
                                                                                }}
                                                                                required
                                                                                spellcheck="false"
                                                                            />
                                                                        </td>
                                                                        {/* Presentación */}
                                                                        <td>
                                                                            <Select
                                                                                defaultOptOff={esHistorial}
                                                                                data-label="unidad"
                                                                                disabled={esHistorial}
                                                                                style={{ minWidth: '180px', ...(primerClick && !v.unidad_max ? { borderColor: 'red' } : {}) }}
                                                                                className="form-control"
                                                                                placeholder="Cantidad Máx."
                                                                                id={"clave_presentacion-max_" + index}
                                                                                // name={"clave_presentacion-max_" + index}
                                                                                value={v.unidad_max}
                                                                                datos={listUnidadMedida}
                                                                                onChange={(e) => {
                                                                                    const _row = presentaciones.map((v, i) => (i === index ? { ...v, unidad_max: e.item.clave } : v))

                                                                                    setPresentaciones(_row)
                                                                                }}
                                                                                required
                                                                                spellcheck="false"
                                                                            />
                                                                        </td>
                                                                        {/* Precio */}
                                                                        <td>
                                                                            <input autoComplete='off'
                                                                                data-label="Preparación"
                                                                                disabled={esHistorial}
                                                                                className="form-control"
                                                                                style={{ minWidth: '130px', ...(primerClick && !v.precio ? { borderColor: 'red' } : {}) }}
                                                                                id={"txt_precio_" + index}
                                                                                // name={"txt_precio_" + index}
                                                                                type="number"
                                                                                maxLength={10}
                                                                                value={v.precio}
                                                                                onChange={(e) => {
                                                                                    if (isNaN(Number(e.target.value))) return

                                                                                    const _row = presentaciones.map((v, i) => (i === index ? { ...v, precio: e.target.value } : v))

                                                                                    setPresentaciones(_row)
                                                                                }}
                                                                                required
                                                                                spellcheck="false"
                                                                            />
                                                                        </td>
                                                                        {/* Botón de Borrar (Compacto) */}
                                                                        {!esHistorial ?
                                                                            <td className="text-center">
                                                                                <button
                                                                                    className="btn btn-danger btn-sm"
                                                                                    type="button"
                                                                                    onClick={() => {
                                                                                        setPresentaciones(l => {
                                                                                            const actual = l[index].value
                                                                                            delete l[index]

                                                                                            const _rows = l.filter(v => v).map(v => {
                                                                                                const opciones = v.list.map(e => {
                                                                                                    return { ...e, activo: actual === e.clave ? true : e.activo }
                                                                                                })

                                                                                                return { ...v, list: opciones }
                                                                                            })

                                                                                            return _rows
                                                                                        })
                                                                                    }}
                                                                                >
                                                                                    <i className="fa fa-trash"></i>
                                                                                </button>
                                                                                {/* <button
                                                                            className="btn btn-danger btn-sm"
                                                                            onClick={() => {
                                                                                setPresentaciones(l => {
                                                                                    const actual = l[index].value
                                                                                    delete l[index]

                                                                                    const _rows = l.filter(v => v).map(v => {
                                                                                        const opciones = v.list.map(e => {
                                                                                            return { ...e, activo: actual === e.clave ? true : e.activo }
                                                                                        })

                                                                                        return { ...v, list: opciones }
                                                                                    })

                                                                                    return _rows
                                                                                })
                                                                            }}>
                                                                            <i className="fa fa-trash"></i>
                                                                        </button> */}
                                                                            </td>
                                                                            : ''
                                                                        }
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div class="col-md-12 mb-3">
                                                <h5 className='title-card-general'>Pasos para la preparación de la receta</h5>
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                {!esHistorial ?
                                                    <button
                                                        className='btn-nuevo'
                                                        type='button'
                                                        onClick={() => {
                                                            setProcedimiento([...procedimiento, {}])
                                                        }}
                                                    >
                                                        <span className="text">Agregar paso +</span>
                                                    </button>
                                                    : ''
                                                }
                                                <br />
                                                <div className="u-steps u-steps-vertical">
                                                    {procedimiento.map((v, index) => <div className="justify-content-end d-flex">
                                                        <div className="col-md-9 mb-3">
                                                            <div className='input-field'>
                                                                <input autoComplete='off'
                                                                    data-label="Preparación"
                                                                    id="txt_preparación" name="txt_preparación"
                                                                    type="text"
                                                                    maxLength={600}
                                                                    value={procedimiento[index].texto ? procedimiento[index].texto : ''}
                                                                    onChange={(e) => {
                                                                        if (esHistorial) return

                                                                        setProcedimiento(l => {
                                                                            l[index] = { ...l[index], texto: e.target.value }
                                                                            return l
                                                                        })
                                                                        setVerify(!verify)
                                                                    }}
                                                                    required
                                                                    spellcheck="false"
                                                                />
                                                                <label htmlFor="validationTooltip02">Paso {index + 1}<span className="text-danger">*</span></label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3 mb-3">
                                                            {!esHistorial ?
                                                                <button
                                                                    className="ms-3 mt-1 btn btn-pill btn-danger"
                                                                    type="button"
                                                                    onClick={() => {
                                                                        setProcedimiento(l => {
                                                                            delete l[index]
                                                                            return l.filter(v => v)
                                                                        })
                                                                    }}
                                                                >X
                                                                </button> : ''
                                                            }
                                                        </div>
                                                    </div>
                                                    )}
                                                </div>
                                            </div>
                                            {(esEditar && (GetPermisos.find(permiso => [2061].includes(permiso)) ? true : false)) ||
                                                (!esEditar && (GetPermisos.find(permiso => [31].includes(permiso)) ? true : false))
                                                ? <div className="col-md-12 mb-3">
                                                    <div className="justify-content-end d-flex">
                                                        <button
                                                            className='btn-nuevo'
                                                            type='button'
                                                            // onClick={onClickForm}
                                                            onClick={() => {
                                                                setPrimerClick(true)
                                                                setFormPag(!formPag)
                                                            }}
                                                        >
                                                            <span className="text">Siguiente</span>
                                                            <div className="icon-container">
                                                                <div className="icon icon--left">
                                                                </div>
                                                                <div className="icon icon--right">
                                                                </div>
                                                            </div>
                                                        </button>
                                                    </div>
                                                </div> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>)
}

export default Default