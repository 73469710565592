import React, { Fragment, useState, useEffect } from 'react';
import Select from '../common/select-basic'
import Tools from '../../js/tools'
import notie from 'notie'
import { Home } from 'react-feather';
import Breadcrumb from '../common/breadcrumbv2';
import { GetPermisos } from '../common/getPermisos'
import { useNavigate, useParams } from 'react-router-dom'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Dropzone from '../common/dropzone';
import SelectFecth from '../common/select-fetch';
import SelectSimple from '../common/select-simple';
import { NumericFormat } from 'react-number-format'
import TablaPrecios from '../common/tabla_estatica';

const Default = ({ esEditar }) => {

    const [formCli, setFormCli] = useState({})
    const [ctgEstados, setCtgEstados] = useState([])
    const [ctgDireccionFiscal, setCtgDireccionFiscal] = useState([])
    const [valEstado, setValEstado] = useState([])
    const [valDepartamento, setValDepartamento] = useState([])
    const [valDireccionFiscal, setValDireccionFiscal] = useState([])
    const [ctgCiudades, setCtgCiudades] = useState([])
    const [ctgDepartamentos, setCtgDepartamentos] = useState([])
    const [valCiudad, setValCiudad] = useState([])
    const [modal5, setModal5] = useState(false);
    const navigate = useNavigate()
    const params = useParams();

    const [catalogos, setCatalogos] = useState({})

    const toggle5 = () => {
        setModal5(!modal5)
    }

    useEffect(() => {
        return Tools.toolFetch({
            ruta: 'rt_clientes_detalle',
            method: 'POST',
            body: params,
        }, (response) => {
            console.log('el response', response);

            if (response.status === 'OK') {
                setCtgEstados(response.estados.datos)
                setCtgCiudades(response.ciudades.datos)
                if (esEditar) {
                    setFormCli(response.result)
                    setValEstado(response.result.ID_CES_CLM)
                    setValCiudad(response.result.ID_CCD_CLM)
                } else {
                    setValEstado(0)
                    setValCiudad(0)
                }
            }
        })
    }, [esEditar])

    const onChangeEstados = (clave) => {
        setValEstado(clave)
        Tools.toolFetch({
            ruta: 'rt_clientes_change_estados',
            method: 'POST',
            body: { clave },
        }, (response) => {
            console.log(response);

            if (response.estatus === 'OK') {
                setCtgCiudades(response.datos)
            }
        })
    }

    const onClickDescQr = () => {
        fetch('/rt_descargar_qr', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                id: params.id
            })
        }).then(response => {
            response.blob().then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = `Cliente-${params.id}.png`;
                a.click();
            }).catch(error => {
                console.log('Error', error);

                notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persiste el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 2, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            })
        })
    }

    const onClickCli = () => {
        const form = Tools.getForm('formCli', { ignore: ['txt_correo', 'txt_num_ext'] })
        console.log(form.data);

        if (form.isEmtyCount > 0) return

        form.data.id = params.id
        Tools.toolFetch({
            ruta: !esEditar ? 'rt_clientes_nuevo' : 'rt_clientes_editar',
            method: 'POST',
            body: form.data,
        }, (response) => {
            console.log(response)

            if (response.status === 'ERROR') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persiste el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 2, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            setTimeout(() => {
                navigate('/clientes')
            }, 2000);

            notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Los datos se han guardado correctamente`,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }

    return (
        <Fragment>
            <Breadcrumb icon={Home} title={'Clientes'} parent={[{ titulo: 'clientes', link: '/clientes' }]} />
            <div className="container-fluid ">
                <div className="mt-3 px-4">
                    {esEditar ?
                        <Modal isOpen={modal5} toggle={toggle5} size='sm' centered={true}>
                            <ModalHeader toggle={toggle5}><button className='btn-nuevo' type='button' onClick={onClickDescQr}>
                                <span className="text">Descargar</span>
                                <div className="icon-container">
                                    <div className="icon icon--left">
                                    </div>
                                    <div className="icon icon--right">
                                    </div>
                                </div>
                            </button>
                            </ModalHeader>
                            <ModalBody>
                                <img width={265} src={`/images/clientes/qr/${params.id}/imageQr.png`} />
                            </ModalBody>
                        </Modal>
                        : ''
                    }
                </div>
                <div className='row'>
                    <form id='formCli' className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className='card'>
                            <div className="card-header d-flex gap-3">
                                <button className='btn-regresar' type='button' onClick={(e) => {
                                    navigate('/clientes')
                                }}>Volver al inicio</button>
                            </div>
                            <div className="card-body">
                                <div className='row'>
                                    <div class="col-md-12 mb-3">
                                        <h5 className='title-card-general'>Datos generales del cliente</h5>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input autoComplete='off'
                                                id="txt_nombre" name="txt_nombre"
                                                type="text"
                                                defaultValue={formCli ? formCli.NOMBRE_CLM : ''}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-user" aria-hidden="true"></i> Nombre<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_nombre" style={{ display: 'none' }}><strong>Falta completar (Nombre)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input autoComplete='off'
                                                id="txt_apellidos" name="txt_apellidos"
                                                type="text"
                                                defaultValue={formCli ? formCli.APELLIDOS_CLM : ''}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-user" aria-hidden="true"></i> Apellidos<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_apellidos" style={{ display: 'none' }}><strong>Falta completar (Apellidos)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input autoComplete='off'
                                                id="txt_correo" name="txt_correo"
                                                type="text"
                                                defaultValue={formCli ? formCli.CORREO_CLM : ''}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-envelope" aria-hidden="true"></i> Correo<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_correo" style={{ display: 'none' }}><strong>Falta completar (Correo)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input autoComplete='off'
                                                id="txt_celular" name="txt_celular"
                                                type="text"
                                                defaultValue={formCli ? formCli.CELULAR_CLM : ''}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-mobile fa-lg" aria-hidden="true"></i> Celular<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_celular" style={{ display: 'none' }}><strong>Falta completar (Celular)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field select'>
                                            <Select
                                                data-label="Estado"
                                                placeholder="Seleccionar estado"
                                                id="clave_estado"
                                                name="clave_estado"
                                                value={valEstado}
                                                datos={ctgEstados}
                                                onChange={(e) => {
                                                    onChangeEstados(e.item.clave)
                                                }}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-map-marker" aria-hidden="true"></i> Estado<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios clave_estado" style={{ display: 'none' }}><strong>Falta completar (Estado)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field select'>
                                            <Select
                                                data-label="Ciudad"
                                                placeholder="Seleccionar ciudad"
                                                id="clave_ciudad"
                                                name="clave_ciudad"
                                                value={valCiudad}
                                                datos={ctgCiudades}
                                                onChange={(e) => { setValCiudad(e.item.clave) }}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-map-marker" aria-hidden="true"></i> Ciudad<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios clave_ciudad" style={{ display: 'none' }}><strong>Falta completar (Ciudad)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input autoComplete='off'
                                                id="txt_calle" name="txt_calle"
                                                type="text"
                                                defaultValue={formCli ? formCli.CALLE_CLM : ''}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-road" aria-hidden="true"></i> Calle<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_calle" style={{ display: 'none' }}><strong>Falta completar (Calle)</strong></span>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <div className='input-field'>
                                            <input autoComplete='off'
                                                id="txt_num_int" name="txt_num_int"
                                                type="text"
                                                defaultValue={formCli ? formCli.NUM_INT_CLM : ''}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-hashtag" aria-hidden="true"></i> Numero interno<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_num_int" style={{ display: 'none' }}><strong>Falta completar (Número interno)</strong></span>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <div className='input-field'>
                                            <input autoComplete='off'
                                                id="txt_num_ext" name="txt_num_ext"
                                                type="text"
                                                defaultValue={formCli ? formCli.NUM_EXT_CLM : ''}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-hashtag" aria-hidden="true"></i> Número externo</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input autoComplete='off'
                                                id="txt_col" name="txt_col"
                                                type="text"
                                                defaultValue={formCli ? formCli.COLONIA_CLM : ''}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-map-marker" aria-hidden="true"></i> Colonia<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_col" style={{ display: 'none' }}><strong>Falta completar (Colonia)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input autoComplete='off'
                                                id="txt_cp" name="txt_cp"
                                                type="text"
                                                defaultValue={formCli ? formCli.CP_CLM : ''}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-hashtag" aria-hidden="true"></i> Codigo postal<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_cp" style={{ display: 'none' }}><strong>Falta completar (Codigo postal)</strong></span>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-md-12 mt-4">
                                        <div className="justify-content-end d-flex">
                                            {esEditar ?
                                                <button className='btn-nuevo' type='button' onClick={toggle5} style={{ marginLeft: '20px' }}>
                                                    <span className="text">Ver Qr</span>
                                                    <div className="icon-container">
                                                        <div className="icon icon--left">
                                                        </div>
                                                        <div className="icon icon--right">
                                                        </div>
                                                    </div>
                                                </button> : ''
                                            }
                                            {(esEditar && (GetPermisos.find(permiso => [2100].includes(permiso)) ? true : false))
                                                || (!esEditar && (GetPermisos.find(permiso => [2099].includes(permiso)) ? true : false))
                                                ?
                                                <button className='btn-nuevo' type='button' onClick={onClickCli} style={{ marginLeft: '20px' }}>
                                                    <span className="text">{!esEditar ? 'Guardar' : 'Editar'}</span>
                                                    <div className="icon-container">
                                                        <div className="icon icon--left">
                                                        </div>
                                                        <div className="icon icon--right">
                                                        </div>
                                                    </div>
                                                </button> : ''
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>)
}

export default Default