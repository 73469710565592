import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Home, Briefcase, BarChart2 } from 'react-feather';
// import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { useParams, useNavigate } from 'react-router-dom'
import { GetPermisos } from '../common/getPermisos';
// import Breadcrumb from '../../components/common/breadcrumb';
import Breadcrumb from '../common/breadcrumbv2';
import Tools from '../../js/tools'
import notie from 'notie'

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Datos from './insumos_form'
import MaxMin from './insumos_maxmin'
import { green } from '@mui/material/colors';

const Default = ({ esEditar, esHistorial }) => {
    const [formIns, setFormIns] = useState({})
    const [img, setImg] = useState({})
    const [listFamilias, setListFamilias] = useState([])
    const [listMarcas, setListMarcas] = useState([])
    const [ctgUnidades, setCtgUnidades] = useState([])
    const [formDatos, setFormDatos] = useState({})
    const [maxMinSucAlm, setMaxMinSucAlm] = useState({})
    const [onClickFormRefresh, setOnClickFormRefresh] = useState(true)
    const [maxMin, setMaxMin] = useState([])
    const [valueTab, setValueTab] = useState(0);

    const params = useParams()
    const firstCurrent = useRef(true)
    const navigate = useNavigate()

    useEffect(() => {
        if (!esEditar) setImg({})

        params.tipo = 1
        Tools.toolFetch({
            ruta: 'rt_insumos_detalle',
            method: 'POST',
            body: params,
        }, (response) => {
            if (response.status === 'OK') {
                setListFamilias(response.familias)
                setListMarcas(response.marcas)
                setCtgUnidades(response.unidadMedida)
                setMaxMin(response.maxMin)
                setFormIns(esEditar ? response.result[0] : { MEDIDA_MAXIMA_INA: '', MEDIDA_MINIMA_INA: '' })
                setMaxMinSucAlm(l => {
                    const maxMin = {}
                    response.sucAlm.forEach(v => {
                        maxMin[v.CLAVE_CHECK] = {
                            idSucAlm: v.ID,
                            tipo: v.TIPO,
                            cantidad_minima: v.CANTIDAD_MINIMA,
                            cantidad_maxima: v.CANTIDAD_MAXIMA,
                            unidad_minima: v.UNIDAD_MINIMA,
                            unidad_maxima: v.UNIDAD_MAXIMA
                        }
                    })
                    return maxMin
                })
            }
        })
    }, [esEditar])

    useEffect(() => {
        if (firstCurrent.current) {
            firstCurrent.current = false
            return
        }

        const dataImg = new FormData()

        dataImg.append('datos', JSON.stringify({ ...formDatos.data, id: params.id, tipo: 1 }))
        dataImg.append('maxMinSucAlm', JSON.stringify(maxMinSucAlm))
        dataImg.append('img', img.imgPortada)
        dataImg.append('id', 1)

        fetch(!esEditar ? '../rt_insumos_nuevo' : '../rt_insumos_editar', {
            method: 'POST',
            body: dataImg
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === 'ERROR') {
                    return notie.alert({
                        type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                        text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persiste el detalle comunica al equipo de Prointernet.`,
                        // stay: Boolean, // optional, default = false
                        time: 2, // optional, default = 3, minimum = 1,
                        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                    })
                }

                if (response.status === 'IMG-TYPE') {
                    return notie.alert({
                        type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                        text: `Inserte un archivo .jpg, .jpeg, .png`,
                        // stay: Boolean, // optional, default = false
                        time: 2, // optional, default = 3, minimum = 1,
                        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                    })
                }

                if (response.status === 'IMG-SIZE') {
                    return notie.alert({
                        type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                        text: `Su imagen excede el límite (5mb)`,
                        // stay: Boolean, // optional, default = false
                        time: 2, // optional, default = 3, minimum = 1,
                        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                    })
                }

                setTimeout(() => {
                    navigate('/insumos')
                }, 2000);

                notie.alert({
                    type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Los datos se han guardado correctamente`,
                    // stay: Boolean, // optional, default = false
                    time: 2, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            })
    }, [onClickFormRefresh])

    const handleChange = (event, newValue) => {
        if (valueTab === 0) return onClickFormVerify(newValue)
        setValueTab(newValue);
    };

    const onClickFormVerify = (value) => {
        const form = Tools.getForm('formIns', { ignore: ['txt_num_ext', 'txt_medida_maxima', 'txt_medida_minima', 'txt_descripcion'] })
        if (form.isEmtyCount > 0) {
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Ingrese los campos: ` + form.isEmtyString,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        if (!img.imgPortada) {
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Ingrese una imagen`,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        setFormDatos(form)
        setValueTab(value)
    }

    return (
        <Fragment>
            <Breadcrumb icon={Home} title={`Insumos`} parent={[{ titulo: 'insumos', link: '/insumos' }]} />
            <div className="container-fluid">
                <div className="row theme-tab">
                    <Box>
                        <TabContext value={valueTab}>
                            <Box>
                                <TabList
                                    TabIndicatorProps={{
                                        style: {
                                            backgroundColor: "#D97D54"
                                        }
                                    }}
                                    style={{ color: "#7E0000" }}
                                    textColor='inherit'
                                    onChange={handleChange}
                                    aria-label="lab API tabs example">
                                    <Tab icon={<BarChart2 />} iconPosition="start" label="Datos" value={0} />
                                    <Tab icon={<BarChart2 />} iconPosition="start" label="Maximos y Minimos" value={1} />
                                </TabList>
                            </Box>
                            <div className="tab-content-cls">
                                <TabPanel value={0}>
                                    <Datos
                                        formIns={formIns}
                                        img={img}
                                        listFamilias={listFamilias}
                                        listMarcas={listMarcas}
                                        ctgUnidades={ctgUnidades}
                                        maxMin={maxMin}
                                        setFormIns={setFormIns}
                                        setImg={setImg}
                                        setListFamilias={setListFamilias}
                                        handleChange={handleChange}
                                        esEditar={esEditar}
                                    />
                                </TabPanel>
                                <TabPanel value={1}>
                                    <MaxMin
                                        ctgUnidades={ctgUnidades}
                                        setMaxMinSucAlm={setMaxMinSucAlm}
                                        maxMinSucAlm={maxMinSucAlm}
                                        setOnClickFormRefresh={setOnClickFormRefresh}
                                        esEditar={esEditar}
                                    />
                                </TabPanel>
                            </div>
                        </TabContext>
                    </Box>
                </div>
            </div>
        </Fragment>
    )
}


export default Default;