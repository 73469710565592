import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Home, Briefcase, BarChart2 } from 'react-feather';
// import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { useParams, useNavigate } from 'react-router-dom'
import Produccion from './produccion_recetas'
import ProduccionHistorial from './produccion_historial'
import ProduccionSucursales from './produccion_sucursales'
import { GetPermisos } from '../common/getPermisos';
// import Breadcrumb from '../../components/common/breadcrumb';
import Breadcrumb from '../common/breadcrumbv2';
import Tools from '../../js/tools'
import notie from 'notie'

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { green } from '@mui/material/colors';

const Default = ({ esEditar, esHistorial }) => {
    const [valueTab, setValueTab] = useState(0);
    const [idProduccion, setIdProduccion] = useState(0)
    const [recetas, setRecetas] = useState([])
    const [listUnionArtInsRe, setListUnionArtInsRe] = useState({})
    const [fechaFormat, setFechaFormat] = useState('')
    const [fechaReqFormat, setFechaReqFormat] = useState('')
    const [ctgSucursales, setCtgSucursales] = useState([])
    const [ctgFamilias, setCtgFamilias] = useState([])
    const [listPerSuc, setListPerSuc] = useState([])
    const [esActializar, setEsActualizar] = useState(true)
    const [permisoComisariato, setPermisoComisariato] = useState(false)
    const [authProd, setAuthProd] = useState(false)
    const [authSucs, setAuthSucs] = useState(false)
    const [fechaLimite, setFechaLimite] = useState(false)
    const [artInsUcAlmacenes, setArtInsUcAlmacenes] = useState([])
    const [formPag, setFormPag] = useState(true)
    const params = useParams()
    const navigate = useNavigate()
    const perAuth = GetPermisos.find(permiso => [2066].includes(permiso)) ? true : false

    // useEffect(() => {
    //     Tools.toolFetch({
    //         ruta: 'rt_produccion_list',
    //         method: 'POST',
    //     }, (response) => {
    //         setCtgSucursales(response.datos)
    //     })

    //     setValueTab(0)
    // }, [esEditar])

    useEffect(() => {
        let idRecetas = {}

        Tools.toolFetch({
            ruta: 'rt_produccion_list',
            method: 'POST',
            body: { idPr: params.id, esEditar }
        }, (response) => {
            if (!response.result.idProduccion) return navigate('/produccion')

            if (response.status !== 'OK') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persiste el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 2, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }


            if (recetas.length) recetas.forEach(v => idRecetas[v.ID_RE] = { refresh: !v.esRefresh })

            console.log(response.result.permisoComisariato);

            setArtInsUcAlmacenes(response.result.artInsUcAlmacenes)
            setPermisoComisariato(response.result.permisoComisariato)
            setFechaFormat(response.result.fechaFormat)
            setFechaReqFormat(response.result.fechaReqFormat)
            setIdProduccion(response.result.idProduccion)
            setAuthProd(response.result.authProd)
            setAuthSucs(response.result.authSucursales)
            setFechaLimite(response.result.fecha_limite)
            setCtgSucursales(response.result.sucursales.map(v => response.result.sucursalesPermisos.includes(v.ID_SUC) || GetPermisos.find(permiso => [4104].includes(permiso)) ? v : null).filter(v => v))
            setListPerSuc(response.result.sucursalesPermisos)
            setRecetas(response.result.recetas.map(v => ({
                ...v,
                valTiempo: v.VAL_TIEMPO,
                formatTablaRefresh: true,
                esRefresh: idRecetas[v.ID_RE] ? idRecetas[v.ID_RE].refresh : true
            })))
            setListUnionArtInsRe(l => {
                response.result.unionArtInsRe.forEach(v => {
                    if (!l[v.NOMBRE_TIPO]) l[v.NOMBRE_TIPO] = {}
                    l[v.NOMBRE_TIPO][v.ID] = v
                })

                return l
            })
            setValueTab(0)
        })
    }, [esActializar, esEditar])

    const handleChange = (event, newValue) => {
        setValueTab(newValue);
    };

    return (
        <Fragment>
            <Breadcrumb icon={Home} title={fechaReqFormat ? `Producción: ${fechaReqFormat}` : 'Nueva Producción'} parent={[{ titulo: 'producción', link: '/produccion' }]} />
            <div className="container-fluid">
                <div className="row theme-tab">
                    <Box>
                        <TabContext value={valueTab}>
                            <Box>
                                <TabList
                                    TabIndicatorProps={{
                                        style: {
                                            backgroundColor: "#D97D54"
                                        }
                                    }}
                                    style={{ color: "#7E0000" }}
                                    textColor='inherit'
                                    onChange={handleChange}
                                    aria-label="lab API tabs example">
                                    {ctgSucursales.map((e, index) => <Tab icon={<BarChart2 />} iconPosition="start" label={e.TITULO_SUC} value={index} />)}
                                    {perAuth && authSucs ? <Tab icon={<BarChart2 />} iconPosition="start" label='Producción' value={ctgSucursales.length + 1} /> : ''}
                                </TabList>
                            </Box>
                            <div className="tab-content-cls">
                                {ctgSucursales.map((v, index) =>
                                    <TabPanel value={index}>
                                        <ProduccionSucursales
                                            esEditar={esEditar}
                                            artInsUcAlmacenes={artInsUcAlmacenes}
                                            setEsActualizar={setEsActualizar}
                                            tituloSucursal={v.TITULO_SUC}
                                            setFechaFormat={setFechaFormat}
                                            idSuc={v.ID_SUC}
                                            idPsu={v.ID_PSU}
                                            authSuc={v.AUTH_SUCURSAL_PSU}
                                            fechaLimite={fechaLimite}
                                            confirmSuc={v.CONFIRMAR_SUCURSAL_PSU}
                                            idProduccion={idProduccion}
                                            ctgFamilias={ctgFamilias}
                                            listPerSuc={listPerSuc}
                                            setCtgFamilias={setCtgFamilias}
                                            listUnionArtInsRe={listUnionArtInsRe}
                                            permisoComisariato={permisoComisariato}
                                            setListUnionArtInsRe={setListUnionArtInsRe}
                                        />
                                    </TabPanel>
                                )}
                                {perAuth && authSucs ? <TabPanel value={ctgSucursales.length + 1}>
                                    <Produccion
                                        esEditar={esEditar}
                                        idProduccion={idProduccion}
                                        authProd={authProd}
                                        setEsActualizar={setEsActualizar}
                                        recetas={recetas}
                                        setRecetas={setRecetas}
                                    />
                                </TabPanel> : ''}
                            </div>
                        </TabContext>
                    </Box>
                    {/* <Tabs className="col-sm-12" defaultTab={selectedTab.toString()}>
                        <TabList className="tabs tab-title">
                            <Tab className="current" tabFor="0">
                                <BarChart2 />Datos
                            </Tab>
                            <Tab tabFor="1">
                                <Briefcase />Insumos/Articulos
                            </Tab>
                        </TabList>
                        <div className="tab-content-cls">
                            <TabPanel tabId="0">
                                <Recetas listArtIns={listArtIns} setListArtIns={setListArtIns} esEditar={esEditar} formRec={formRec} setFormRec={setFormRec} procedimiento={procedimiento} setProcedimiento={setProcedimiento} setSelectedTab={setSelectedTab} />
                            </TabPanel>
                            <TabPanel tabId="1">
                                <RecetasArtIns listArtIns={listArtIns} setListArtIns={setListArtIns} formRec={formRec} esEditar={esEditar} />
                            </TabPanel>
                        </div>
                    </Tabs> */}
                </div>
            </div>
        </Fragment>
    )
}


export default Default;