import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import Tools from '../../js/tools'
import { BtnVer, BtnEliminar } from '../common/table_buttons'
import { GetPermisos } from '../common/getPermisos';
import Table from '../common/tabla'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import Select from '../common/select-basic'
import notie from 'notie'
import Breadcrumb from '../common/breadcrumbv2';
import { Briefcase } from 'react-feather';
import tools from '../../js/tools';

const Default = ({
    setEsActualizar,
    idInventaraio,
    ctgFamilias,
    fechaLimite,
    idSucursal,
    estatusSuc,
    listPerSuc
}) => {
    const [esRefresh, setEsRefresh] = useState(false)
    const [inicioPag, setInicioPag] = useState(true)
    const [esRefreshCopia, setEsRefreshCopia] = useState(false)
    const [esRefreshActualizar, setEsRefreshActualizar] = useState(false)
    const [listUnionArtInsReCopia, setListUnionArtInsReCopia] = useState({})
    const [recetas, setRecetas] = useState([])
    const [tipoVal, setTipoVal] = useState(1)
    const [valFamilia, setValFamilia] = useState(0)
    const [valNombreFamilia, setValNombreFamilia] = useState('')
    const [tipoValNombre, setTipoValNombre] = useState('')
    const [ingredientes, setIngredientes] = useState([])
    const [modal5, setModal5] = useState();
    const firstCurrent = useRef(true)
    const navigate = useNavigate()
    const params = useParams()


    const estatusCintillo = [
        <Alert color="warning" className="dark">
            Pendiente
        </Alert>,
        <Alert color="info" className="dark">
            Confirmado
        </Alert>
    ]

    useEffect(() => {
        if (inicioPag) return setInicioPag(false)
        setEsRefresh(l => !l)
    }, [estatusSuc])

    const actualizarIn = (idIia, inventario) => {
        Tools.toolFetch({
            ruta: 'rt_inventario_actualizar_artins',
            method: 'POST',
            body: { idIia, inventario }
        }, (response) => {
            if (response.status === 'ERROR') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persiste el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 2, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }
        })
    }

    const cambiarEstatus = (estatus) => {
        return notie.confirm({
            text: `¿Desea confirmar el siguiente inventario?`,
            submitText: 'Si, Autorizar',
            cancelText: 'No, Cancelar',
            cancelCallback: function (value) {
            },
            submitCallback: function (value) {
                Tools.toolFetch({
                    ruta: 'rt_inventario_cambiar_estatus',
                    method: 'POST',
                    body: { idInventaraio, idSucursal, estatus }
                }, (response) => {
                    if (response.status === 'ERROR') {
                        return notie.alert({
                            type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                            text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persiste el detalle comunica al equipo de Prointernet.`,
                            // stay: Boolean, // optional, default = false
                            time: 2, // optional, default = 3, minimum = 1,
                            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                        })
                    }

                    setEsActualizar(l => !l)
                    notie.alert({
                        type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                        text: `El inventario ha sido confirmado.`,
                        // stay: Boolean, // optional, default = false
                        time: 2, // optional, default = 3, minimum = 1,
                        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                    })
                })
            },
        })
    }

    // useEffect(() => {
    //     setEsRefresh(l => !l)
    // }, [])

    // const toggle5 = () => {
    //     console.log(listUnionArtInsRe);

    //     let clon = {}
    //     opTipos.forEach(v => {
    //         clon[v.label] = Object.assign({}, listUnionArtInsRe[v.label])
    //     })

    //     setListUnionArtInsReCopia(clon)
    //     setTipoValNombre(opTipos[0].label)
    //     setTipoVal(1)
    //     setModal5(!modal5)
    //     setEsRefreshCopia(l => !l)
    // }


    // const estatusCintillo = () => {
    //     if (!authSuc && !fechaLimite) return <Alert color="primary" className="dark">
    //         Tiempo limite
    //     </Alert>

    //     if (authSuc) return <Alert color="success" className="dark">
    //         Autorizado
    //     </Alert>

    //     if (confirmSuc) return <Alert color="info" className="dark">
    //         Confirmado
    //     </Alert>

    //     return <Alert color="warning" className="dark">
    //         Pendiente
    //     </Alert>
    // }

    return (
        <Fragment>
            {/* <Modal isOpen={modal5} toggle={toggle5}>
                <ModalHeader toggle={toggle5}>{valNombreFamilia}</ModalHeader>
                <ModalBody>
                    <div className="mt-3 px-4">
                        <div className='input-field select'>
                            <Select
                                data-label="Auditor"
                                defaultOptOff={true}
                                datos={opTipos}
                                value={tipoVal}
                                onChange={(e) => {
                                    setTipoValNombre(e.item.label)
                                    setTipoVal(parseInt(e.item.clave))
                                    setEsRefreshCopia(l => !l)
                                    // setListUnionArtInsReCopia(listUnionArtInsRe[e.item.label])
                                }}
                                // required
                                spellcheck="false"
                            />
                            <label htmlFor="validationTooltip02"><i class="fa fa-comment" aria-hidden="true"></i> Seleccionar Articulos/Insumos <span className="text-danger">*</span></label>
                        </div>
                    </div>
                    <Table
                        placeholderSearch={`Buscar ${tipoValNombre}...`}
                        tablaMensaje={`No se encuentran "${tipoValNombre}" en esta familia`}
                        noRutaDinamica={true}
                        cargando={false}
                        headers={[
                            {
                                nombre: 'PORTADA', tipo: 'html', texto: 'PORTADA', html: (d) => {
                                    return <div style={{ textAlign: 'center', width: '8em' }}>
                                        <img src={`/images/${d.TIPO === 1 ? 'articulos-insumos' : 'recetas'}/` + d.ID + '/' + d.RUTA_IMAGEN} width={100} height={100} />
                                    </div>
                                }
                            },
                            {
                                nombre: 'NOMBRE', tipo: 'html', texto: 'NOMBRE_INA', html: (d) => {
                                    return <div>
                                        <strong>{d.NOMBRE}</strong><br />
                                    </div>
                                }
                            },
                            {
                                nombre: 'SELECCIONAR', tipo: 'check', texto: 'ID_INA', callback: (d) => {
                                    setListUnionArtInsReCopia(l => {
                                        l[tipoValNombre][d.ID] = { ...l[tipoValNombre][d.ID], ACTIVO_PRODUCCION: !l[tipoValNombre][d.ID].ACTIVO_PRODUCCION }

                                        console.log(l[tipoValNombre]);
                                        return l
                                    })
                                }
                            },
                        ]}
                        totalField={'NR'}
                        path={'/rt_produccion_artins_list'}
                        refresh={esRefreshCopia}
                        body={{ tipo: 1, valNombre: tipoValNombre, valFamilia, list: listUnionArtInsReCopia }}
                        paginadorTop={true}
                    >
                    </Table>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={editarFamilia}>Guardar</Button>
                    <Button color="secondary" onClick={toggle5}>Cancelar</Button>
                </ModalFooter>
            </Modal> */}
            {estatusCintillo[estatusSuc - 1]}
            {ctgFamilias.map((v, index) =>
                <Fragment>
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div class="col-md-12 mb-3">
                                    <h5 className='title-card-general'>{v.TITULO_FI}</h5>
                                </div>
                                <div className="col-xl-12 col-md-12">
                                    <div className="mt-3 px-4">
                                        <Table
                                            noRutaDinamica={true}
                                            cargando={false}
                                            // htmlTopleft={!esEditar && !confirmSuc ?
                                            //     <div className="col-md-6">
                                            //         <div className="d-flex justify-content-between align-items-end">
                                            //             <button className='btn-nuevo' onClick={() => {
                                            //                 setValFamilia(v.clave)
                                            //                 setValNombreFamilia(v.label)
                                            //                 toggle5()
                                            //             }}
                                            //             >
                                            //                 <span className="text">Editar</span>
                                            //                 <div className="icon-container">
                                            //                     <div className="icon icon--left">
                                            //                     </div>
                                            //                     <div className="icon icon--right">
                                            //                     </div>
                                            //                 </div>
                                            //             </button>
                                            //         </div>
                                            //     </div>
                                            //     : ''
                                            // }
                                            headers={[
                                                {
                                                    nombre: 'PORTADA', tipo: 'html', texto: 'PORTADA', html: (d) => {
                                                        return <div style={{ textAlign: 'center' }}>
                                                            <img src={'/images/articulos-insumos/' + d.ID_INA + '/' + d.RUTA_IMAGEN_INA} width={100} height={100} />
                                                        </div>
                                                    }
                                                },
                                                {
                                                    nombre: 'NOMBRE', tipo: 'html', texto: 'NOMBRE', html: (d) => {
                                                        return <div>
                                                            <strong>{d.NOMBRE_INA}</strong><br />
                                                        </div>
                                                    }
                                                },
                                                {
                                                    nombre: 'TITULO', tipo: 'html', texto: 'TITULO', html: (d) => {
                                                        return <div>
                                                            <strong>{d.TITULO_INA}</strong><br />
                                                        </div>
                                                    }
                                                },
                                                {
                                                    nombre: 'TIPO', tipo: 'html', texto: 'TIPO', html: (d) => {
                                                        return <div>
                                                            <strong>{d.TIPO}</strong><br />
                                                        </div>
                                                    }
                                                },
                                                {
                                                    nombre: 'INVENTARIO',
                                                    tipo: 'input',
                                                    disabled: (!(GetPermisos.find(permiso => [3104].includes(permiso)) && listPerSuc.includes(idSucursal))) || !fechaLimite || estatusSuc !== 1,
                                                    texto: 'INVENTARIO',
                                                    valueOp: (fila, v) => {
                                                        let valor = v

                                                        if (valor < 0) valor = 0
                                                        if (valor > 9999999) return

                                                        actualizarIn(fila.CLAVE_CHECK, valor)
                                                        return { INVENTARIO: v }
                                                    }
                                                },
                                                {
                                                    nombre: 'UNIDAD MINIMA', tipo: 'html', texto: 'UNIDAD_MINIMA', html: (d) => {
                                                        return <div>
                                                            <strong>{d.UNIDAD_MINIMA}</strong><br />
                                                        </div>
                                                    }
                                                },
                                                {
                                                    nombre: 'UNIDAD MAXIMA', tipo: 'html', texto: 'UNIDAD_MAXIMA', html: (d) => {
                                                        return <div>
                                                            <strong>{d.UNIDAD_MAXIMA}</strong><br />
                                                        </div>
                                                    }
                                                },
                                            ].filter(v => v)}
                                            totalField={'NR'} /* NR = número de resultados totales */
                                            path={'/rt_inventario_artinsfa'}
                                            refresh={esRefresh}
                                            esPaginador={false}
                                            // paginadorTop={true}
                                            sinBuscador={true}
                                            body={{ idIn: idInventaraio, idFi: v.ID_FI, idSuc: idSucursal }}
                                        >
                                        </Table>
                                    </div>
                                </div>
                                {index === ctgFamilias.length - 1 ?
                                    <Fragment>
                                        {estatusSuc === 1 ?
                                            <div div className="col-md-12 mb-3">
                                                <div className="justify-content-end d-flex">
                                                    <button
                                                        className='btn-nuevo'
                                                        type='button'
                                                        onClick={() => { cambiarEstatus(2) }}
                                                    >
                                                        <span className="text">Confirmar</span>
                                                        <div className="icon-container">
                                                            <div className="icon icon--left">
                                                            </div>
                                                            <div className="icon icon--right">
                                                            </div>
                                                        </div>
                                                    </button>
                                                </div>
                                            </div>
                                            : ''}
                                    </Fragment>
                                    : ''
                                }
                            </div>
                        </div>
                    </div>
                </Fragment>)
            }
        </Fragment >
    );
};

export default Default;