import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Home, Briefcase, BarChart2 } from 'react-feather';
// import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { useParams, useNavigate } from 'react-router-dom'
import InventarioSucursales from './inventario_sucursales'
import { GetPermisos } from '../common/getPermisos';
// import Breadcrumb from '../../components/common/breadcrumb';
import Breadcrumb from '../common/breadcrumbv2';
import Tools from '../../js/tools'
import notie from 'notie'

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { green } from '@mui/material/colors';

const Default = ({ esEditar, esHistorial }) => {
    const [valueTab, setValueTab] = useState(0);
    const [idInventario, setIdInventario] = useState(0)
    const [recetas, setRecetas] = useState([])
    const [listUnionArtInsRe, setListUnionArtInsRe] = useState({})
    const [fechaFormat, setFechaFormat] = useState('')
    const [ctgSucursales, setCtgSucursales] = useState([])
    const [ctgFamilias, setCtgFamilias] = useState([])
    const [listPerSuc, setListPerSuc] = useState([])
    const [esActializar, setEsActualizar] = useState(true)
    const [authProd, setAuthProd] = useState(false)
    const [authSucs, setAuthSucs] = useState(false)
    const [fechaLimite, setFechaLimite] = useState(false)
    const [formPag, setFormPag] = useState(true)
    const params = useParams()
    const navigate = useNavigate()
    const perAuth = GetPermisos.find(permiso => [2066].includes(permiso)) ? true : false

    // useEffect(() => {
    //     Tools.toolFetch({
    //         ruta: 'rt_produccion_list',
    //         method: 'POST',
    //     }, (response) => {
    //         setCtgSucursales(response.datos)
    //     })

    //     setValueTab(0)
    // }, [esEditar])

    useEffect(() => {
        Tools.toolFetch({
            ruta: 'rt_inventario_list',
            method: 'POST',
            body: { data: params }
        }, (response) => {
            setListPerSuc(response.result.permisosSuc)
            setIdInventario(response.result.idInventario)
            setFechaLimite(response.result.fechaLimite)
            setCtgSucursales(response.result.sucursales)
            setCtgFamilias(response.result.familias)
            setFechaFormat(response.result.fechaFormat)
        })
    }, [esActializar])

    const handleChange = (event, newValue) => {
        setValueTab(newValue);
    };

    return (
        <Fragment>
            <Breadcrumb icon={Home} title={`Inventario: ${fechaFormat}`} parent={[{ titulo: 'inventario', link: '/inventario' }]} />
            <div className="container-fluid">
                <div className="row theme-tab">
                    <Box>
                        <TabContext value={valueTab}>
                            <Box>
                                <TabList
                                    TabIndicatorProps={{
                                        style: {
                                            backgroundColor: "#D97D54"
                                        }
                                    }}
                                    style={{ color: "#7E0000" }}
                                    textColor='inherit'
                                    onChange={handleChange}
                                    aria-label="lab API tabs example">
                                    {ctgSucursales.map((e, index) => <Tab icon={<BarChart2 />} iconPosition="start" label={e.TITULO_SUC} value={index} />)}
                                </TabList>
                            </Box>
                            <div className="tab-content-cls">
                                {ctgSucursales.map((v, index) =>
                                    <TabPanel value={index}>
                                        <InventarioSucursales
                                            listPerSuc={listPerSuc}
                                            fechaLimite={fechaLimite}
                                            idInventaraio={idInventario}
                                            idSucursal={v.ID_SUC}
                                            ctgFamilias={ctgFamilias}
                                            estatusSuc={v.ESTATUS_INS}
                                            setEsActualizar={setEsActualizar}
                                        />
                                    </TabPanel>
                                )}
                            </div>
                        </TabContext>
                    </Box>
                    {/* <Tabs className="col-sm-12" defaultTab={selectedTab.toString()}>
                        <TabList className="tabs tab-title">
                            <Tab className="current" tabFor="0">
                                <BarChart2 />Datos
                            </Tab>
                            <Tab tabFor="1">
                                <Briefcase />Insumos/Articulos
                            </Tab>
                        </TabList>
                        <div className="tab-content-cls">
                            <TabPanel tabId="0">
                                <Recetas listArtIns={listArtIns} setListArtIns={setListArtIns} esEditar={esEditar} formRec={formRec} setFormRec={setFormRec} procedimiento={procedimiento} setProcedimiento={setProcedimiento} setSelectedTab={setSelectedTab} />
                            </TabPanel>
                            <TabPanel tabId="1">
                                <RecetasArtIns listArtIns={listArtIns} setListArtIns={setListArtIns} formRec={formRec} esEditar={esEditar} />
                            </TabPanel>
                        </div>
                    </Tabs> */}
                </div>
            </div>
        </Fragment>
    )
}


export default Default;