import React, { Fragment, useState, useEffect } from 'react';
import Select from '../common/select-basic'
import Tools from '../../js/tools'
import notie from 'notie'
import { Home } from 'react-feather';
import { GetPermisos } from '../common/getPermisos';
import Breadcrumb from '../common/breadcrumbv2';
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import Dropzone from '../common/dropzone';
import NewDropzone from '../common/dropzone2';
import SelectFecth from '../common/select-fetch';
import SelectSimple from '../common/select-simple';
import { NumericFormat } from 'react-number-format'
import TablaPrecios from '../common/tabla_estatica';

const Default = ({
    esEditar,
    formIns,
    img,
    listFamilias,
    listMarcas,
    ctgUnidades,
    maxMin,
    handleChange,
    setFormIns,
    setImg,
    setListFamilias
}) => {
    const params = useParams();
    const navigate = useNavigate()
    const [catalogos, setCatalogos] = useState({})

    return (
        <Fragment>
            <div className="container-fluid ">
                <div className='row'>
                    <form id='formIns' className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className='card'>
                            <div className="card-header d-flex justify-content-between">
                                <button className='btn-regresar' type='button' onClick={(e) => {
                                    navigate('/articulos')
                                }}>Volver al inicio</button>
                            </div>
                            <div className="card-body">
                                <div className='row'>
                                    <div className="col-md-12 mb-3">
                                        <h5 className='title-card-general'>Datos generales del articulo</h5>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input autoComplete='off'
                                                data-label="Nombre"
                                                id="txt_nombre" name="txt_nombre"
                                                type="text"
                                                maxLength={150}
                                                value={formIns.NOMBRE_INA}
                                                required
                                                onChange={(e) => setFormIns(l => ({ ...l, NOMBRE_INA: e.target.value }))}
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><span class="icon-icono_Materia-Prima"></span> Nombre<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_nombre" style={{ display: 'none' }}><strong>Falta completar (Nombre)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input autoComplete='off'
                                                data-label="Titulo"
                                                id="txt_titulo" name="txt_titulo"
                                                type="text"
                                                maxLength={150}
                                                defaultValue={formIns.TITULO_INA}
                                                required
                                                onChange={(e) => setFormIns(l => ({ ...l, TITULO_INA: e.target.value }))}
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-tags" aria-hidden="true"></i> Título<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_titulo" style={{ display: 'none' }}><strong>Falta completar (Titulo)</strong></span>
                                    </div>
                                    {/* <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input autoComplete='off'
                                                id="txt_clave_interna" name="txt_clave_interna"
                                                type="text"
                                                maxLength={50}
                                                defaultValue={formIns ? formIns.CLAVE_INTERNA_INA : ''}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-hashtag" aria-hidden="true"></i> Clave interna<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_clave_interna" style={{ display: 'none' }}><strong>Falta completar (Clave interna)</strong></span>
                                    </div> */}
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field select'>
                                            <Select
                                                data-label="Familia"
                                                id="clave_familia"
                                                name="clave_familia"
                                                value={formIns.ID_FI_INA ? formIns.ID_FI_INA : ''}
                                                datos={listFamilias}
                                                onChange={(e) => { setFormIns(l => { return { ...l, ID_FI_INA: e.item.clave } }) }}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-list-ul" aria-hidden="true"></i> Familias<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios clave_familia" style={{ display: 'none' }}><strong>Falta completar (Familia)</strong></span>
                                    </div>
                                    {/* <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input
                                                autoComplete='off'
                                                id="txt_sku" name="txt_sku"
                                                type="text"
                                                maxLength={20}
                                                defaultValue={formIns ? formIns.SKU_INA : ''}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-hashtag" aria-hidden="true"></i> SKU<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_sku" style={{ display: 'none' }}><strong>Falta completar (SKU)</strong></span>
                                    </div> */}
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field select'>
                                            <Select
                                                data-label="Marca"
                                                id="clave_marca"
                                                name="clave_marca"
                                                value={formIns.ID_MAR_INA ? formIns.ID_MAR_INA : ''}
                                                datos={listMarcas}
                                                onChange={(e) => { setFormIns(l => { return { ...l, ID_MAR_INA: e.item.clave } }) }}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-list-ul" aria-hidden="true"></i> Marca<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios clave_marca" style={{ display: 'none' }}><strong>Falta completar (Marca)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input
                                                data-label="Medida maxima"
                                                autoComplete='off'
                                                id="txt_medida_maxima" name="txt_medida_maxima"
                                                type="text" s
                                                maxLength={10}
                                                value={formIns.MEDIDA_MAXIMA_INA}
                                                onChange={(e) => {
                                                    if (isNaN(Number(e.target.value))) return
                                                    setFormIns(l => ({ ...l, MEDIDA_MAXIMA_INA: e.target.value }))
                                                }}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-level-up" aria-hidden="true"></i> Medida máxima<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_medida_maxima" style={{ display: 'none' }}><strong>Falta completar (Medida máxima)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field select'>
                                            <Select
                                                data-label="Unidad maxima"
                                                id="clave_unidad_max"
                                                name="clave_unidad_max"
                                                value={formIns.ID_CU_MAX_INA}
                                                datos={ctgUnidades}
                                                onChange={(e) => { setFormIns(l => { return { ...l, ID_CU_MAX_INA: e.item.clave } }) }}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-level-up" aria-hidden="true"></i> Unidad de medida máxima<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios clave_unidad_max" style={{ display: 'none' }}><strong>Falta completar (Unidad de medida)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input
                                                data-label="Medida minima"
                                                autoComplete='off'
                                                id="txt_medida_minima" name="txt_medida_minima"
                                                type="number"
                                                maxLength={10}
                                                value={formIns.MEDIDA_MINIMA_INA}
                                                onChange={(e) => {
                                                    if (isNaN(Number(e.target.value))) return
                                                    setFormIns(l => ({ ...l, MEDIDA_MINIMA_INA: e.target.value }))
                                                }}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-level-down" aria-hidden="true"></i> Medida mínima<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_medida_minima" style={{ display: 'none' }}><strong>Falta completar (Medida mínima)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field select'>
                                            <Select
                                                data-label="Unidad minima"
                                                id="clave_unidad_min"
                                                name="clave_unidad_min"
                                                value={formIns.ID_CU_MIN_INA}
                                                datos={ctgUnidades}
                                                onChange={(e) => { setFormIns(l => { return { ...l, ID_CU_MIN_INA: e.item.clave } }) }}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-level-down" aria-hidden="true"></i> Unidad de medida mínima<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios clave_unidad_min" style={{ display: 'none' }}><strong>Falta completar (Unidad de medida)</strong></span>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <div className='input-field'>
                                            <textarea
                                                autoComplete='off'
                                                id="txt_descripcion" name="txt_descripcion"
                                                type="text"
                                                maxLength={1000}
                                                value={formIns.DESCRIPCION_INA}
                                                onChange={(e) => {
                                                    setFormIns({ ...formIns, DESCRIPCION_INA: e.target.value })
                                                }}
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-comment" aria-hidden="true"></i> Descripción<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_descripcion" style={{ display: 'none' }}><strong>Falta completar (Descripción)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label className="form-label labelupload">Portada (600 x 400)<span className="text-danger">*</span></label>
                                        <div className="input-group" style={{ borderRadius: '15px' }}>
                                            <div className="input-group-prepend" placeholder='Subir archivo'></div>
                                            {esEditar ?
                                                <Dropzone
                                                    initialFiles={formIns ? formIns.ruta_imagen : null}
                                                    fileName={formIns ? formIns.ruta_imagen : ''}
                                                    inputText={'Da click o arrastra una imagen .jpg, .jpeg, .png (5mb)...'}
                                                    onImg={(datos) => { setImg((s) => ({ ...s, statusPortada: datos.status, imgPortada: datos.file })) }}
                                                /> :
                                                <NewDropzone
                                                    initialFiles={null}
                                                    fileName={''}
                                                    inputText={'Da click o arrastra una imagen .jpg, .jpeg, .png (5mb)...'}
                                                    onImg={(datos) => { setImg((s) => ({ ...s, statusPortada: datos.status, imgPortada: datos.file })) }}
                                                />
                                            }
                                        </div>
                                        {/* <span className="text-danger" style={{ display: img.statusPortada === 'removed' ? '' : 'none' }}><strong>Falta completar (Portada)</strong></span> */}
                                        {/* {esEditar ?
                                            <div className="justify-content-end d-flex mt-2">
                                                <button className="btn btn-primary btn-sm d-flex align-items-center" type="button"
                                                // onClick={(e) => guardarImg(1)} 
                                                >Guardar Portada</button>
                                            </div>
                                            : ''} */}
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <div className="justify-content-end d-flex">
                                            <button className='btn-nuevo' type='button' onClick={(e) => { handleChange(e, 1) }}>
                                                <span className="text">Siguiente</span>
                                                <div className="icon-container">
                                                    <div className="icon icon--left">
                                                    </div>
                                                    <div className="icon icon--right">
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>)
}

export default Default