import Dropzone from 'react-dropzone-uploader'
import { useState, useEffect } from 'react'
import Tools from '../../js/tools'

const Default = ({
    onImg,
    inputContent,
    maxFiles,
    initialFiles,
    inputText,
    fileName,
    canRemove
}) => {
    const [initialFilesBase64, setInitialFilesBase64] = useState([])
    const handleChangeStatus = ({ meta, file }, status) => {
        if (status === 'done' || status === 'removed') {
            if (onImg)
                onImg({ meta, file: status === 'done' ? file : null, status });
        }
        console.log(status, 'ingormacion');
    }

    useEffect(() => {
        console.log('entra en dropzone 2', canRemove);

        if (initialFiles !== null && initialFiles !== undefined) {
            Tools.urlToObject(initialFiles, fileName)
                .then((response) => {
                    setInitialFilesBase64([response])
                })
        } else {
            setInitialFilesBase64([])
        }
    }, [initialFiles])

    return <Dropzone
        canRemove={canRemove !== null ? canRemove : true}
        initialFiles={initialFilesBase64}
        onChangeStatus={handleChangeStatus}
        maxFiles={!maxFiles ? 1 : maxFiles}
        inputContent={!inputContent ? (!inputText ? "Da click o arrastra un archivo..." : inputText) : ''}
        submitButtonDisabled={files => !maxFiles ? files.length === 1 : files.length >= maxFiles}
    />
}

export default Default